import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoaderService } from '../loader/services/loader.service';
import { generateAPIEvent } from 'src/app/inactivity';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    public loader: LoaderService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    generateAPIEvent();
//    this.notification.toastrService.clear();
    return next.handle(request).pipe(      
      //retry(1),
      catchError((error: HttpErrorResponse) => {     
        this.loader.setLoading(false);
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          if (error!= undefined && error.error != undefined) {
            if(error.error.hasOwnProperty("error_code") && error.error.hasOwnProperty("error_message"))
              {
                if(error.error.error_code=="server_error")
                return throwError(() => <any> { "data":{"responseCode":"ERRSRV","message":error.error.error_message}});
                else
                return throwError(() => JSON.parse(error.error.error_message));
              }
              if(error.error.hasOwnProperty("error_description"))
               errorMessage = error.error.error_description;
              else if(error.hasOwnProperty("status")){
                if(error.status==0)
                return throwError(() =><any> { "data":{"responseCode":"ERRVPN","message":"VPN connectivity issue"}});
              }
            
          }
          else { // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${
              error.message
              }`;
          }
        }

        //this.notification.onError(error.message);
        if(errorMessage!=undefined)
        return throwError(() => errorMessage);
        else
        return throwError(errorMessage);
      })
    );
  }
}
