export interface Metadata {
	id:number,
	name: string,
	label: string,
	value: string,
	type: string,
	options:MetadataControlOptionModel,
	validators:MetadataControlValidatorsModel,
	isActive?:boolean,
	isDeleted?:boolean,
	createdBy?:string,
	createdOn?:Date,
	updatedBy?:string,
	updatedOn?:Date
  }
export interface MetadataControlOptionModel{
	min: number,
	max: number,
	step: number,
	icon: string
}
export interface MetadataControlValidatorsModel{
	required: boolean,
	minLength: number
}
 export interface ControlTypeModel {
    name: string;
    value: string;
}
  export interface MetadataModel {
	details: Metadata;
  }
  export interface Column {
	field: string;
	header: string;
  }
export interface GroupMetadata{
	id:number,
	groupCode:string,
	groupName:string,
	metadataIds:Array<number>,
    isActive?:boolean,
	isDeleted?:boolean,
	createdBy?:string,
	createdOn?:Date,
	updatedBy?:string,
	updatedOn?:Date,
	modelData?:Array<any>,
	metadataDbMappingList?:Array<any> //for child data obly for fetch not for save
}
export class GroupMetadataCodeName{
	id?:number;
	groupCode?:string;
	groupName?:string ;
}
export interface ITable {
	  id: number ,
      tableName: string ,
      schemaName: string ,
      descriptions: string ,
      columns:Array<IColumn>,
	  rowVersion:number, 
	  isLock:boolean,
	  isExecuted:boolean,
	  isActive:boolean,
	  isDeleted:boolean,
	  createdBy:string,
	  updatedBy:string,
	  createdOn:string,
	  updatedOn:string,

}
export interface IColumn {
	id: number , 
	tableId:number,
	tableName:string,
	schemaName:string,
	columnName:string, 
	newColumnName:string, 
	dataType:string, 
	length:number,
	isNull:boolean,
	isPK:boolean,
	defaultValue:string,
	descriptions:string,
	isLookup:boolean,
	isExecuted:boolean,
	isActive:boolean,
	isDeleted:boolean,
	createdBy:string,
	updatedBy:string,
	createdOn:string,
	updatedOn:string,

}
export interface IColumnSearch {
	id: number , 
	tableId:number,
	tableName:string,
	schemaName:string,
	columnName:string,
	name:string, 
	dataType:string, 
	length:number,
	isNull:boolean,
	isPK:boolean,
	defaultValue:string,
	descriptions:string,
	isLookup:boolean,
	isExecuted:boolean,
	isActive:boolean,
	isDeleted:boolean,
	createdBy:string,
	updatedBy:string,
	createdOn:string,
	updatedOn:string,
}
export class TableRelation {
	id?: number ; 
	relationName?:string;
	descriptions?:string;
	parentTableColumnId?:number;
	parentTableColumn?:string;
	parentTable?:string;
	parentSchema?:string;

	childTableColumnId?:number;
	childTableColumn?:string;
	childTable?:string;
	childSchema?:string;
		
	rowVersion?:number; 
	isLock?:boolean;
	isExecuted?:boolean;
	isActive?:boolean;
	isDeleted?:boolean;
	createdBy?:string;
	updatedBy?:string;
	createdOn?:string;
	updatedOn?:string;

}
export class TRelation {
	id?: number ; 
	relationName?:string;
	descriptions?:string;
	parentTableColumnId?:number;
	parentTableColumn?:string;
	parentTable?:string;
	parentSchema?:string;
	parentName?:string;

	childTableColumnId?:number;
	childTableColumn?:string;
	childTable?:string;
	childSchema?:string; 
	childName?:string; 

}
export class ModuleSetup {
	id?: number ; 
	name?:string;
	screenName?:string;
	descriptions?:string;
	tabName?:string;
	metadataGroupId?:number
	metadataGroupCode?:string;
	metadataGroupName?:string;
	sequenceNo?:number; 
	tag?:string;
	isActive?:boolean;
	isDeleted?:boolean;
	createdBy?:string;
	updatedBy?:string;
	createdOn?:string;
	updatedOn?:string;

}

export interface HelpMetadata {
	id:number,
	helpCode:string,
	screenName: string,
	viewName: string,
	tagName: string,
	htmlText: string, 
	isActive?:boolean,
	isDeleted?:boolean,
	createdBy?:string,
	createdOn?:Date,
	updatedBy?:string,
	updatedOn?:Date
  }

export  function fnControlTypeData ( ) {
	return [
		{ name: 'Text', value: 'Text' },
		{ name: 'Textarea', value: 'Textarea' },
		{ name: 'Checkbox', value: 'Checkbox' },
		{ name: 'Password', value: 'Password' },
		{ name: 'Range', value: 'Range' },
		{ name: 'Dropdown', value: 'Dropdown' },
		{ name: 'Hidden', value: 'Hidden' },
		{ name: 'Editor', value: 'Editor' }
	];
  }
  export  function fnTableList ( ) :Array<ITable>{
	return [
		{id:1,
		tableName:"m_company_group",
		schemaName:"products",
		descriptions:"to hold company group master data",
		rowVersion:0,
		isLock: false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-28T12:39:05.950462",
		updatedOn:"2023-08-28T12:39:05.950462",
		columns:[]
		},
		{id:2,
		tableName:"t_product_title",
		schemaName:"products",
		descriptions:"to hold product title record",
		rowVersion:0,
		isLock: false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-09-01T12:39:05.950462",
		updatedOn:"2023-09-02T12:39:05.950462",
		columns:[]
		}

	];
  } 
  export  function fnTableColumnsList ( ):Array<IColumn> {
	return [
		{id:1,
		tableId:1,
		tableName:"m_company_group",
		schemaName:"products",
		columnName:"id",
		newColumnName:"id",
		dataType:"integer",
		length:0,
		isNull:false,
		isPK:true,
		defaultValue:"",
		descriptions:"",
		isLookup:false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		}, 
		{id:2,
		tableId:1,
		tableName:"m_company_group",
		schemaName:"products",
		columnName:"name",
		newColumnName:"name",
		dataType:"varchar",
		length:20,
		isNull:false,
		isPK:false,
		defaultValue:"",
		descriptions:"",
		isLookup:false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		},

		{id:3,
		tableId:2,
		tableName:"t_product_title",
		schemaName:"products",
		columnName:"id",
		newColumnName:"id",
		dataType:"serial",
		length:0,
		isNull:false,
		isPK:true,
		defaultValue:"",
		descriptions:"",
		isLookup:false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		}, 
		{id:4,
		tableId:2,
		tableName:"t_product_title",
		schemaName:"products",
		columnName:"isbn",
		newColumnName:"isbn",
		dataType:"varchar",
		length:13,
		isNull:false,
		isPK:false,
		defaultValue:"",
		descriptions:"",
		isLookup:false,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		}, 
		{id:5,
		tableId:2,
		tableName:"t_product_title",
		schemaName:"products",
		columnName:"company_group_id",
		newColumnName:"company_group_id",
		dataType:"integer",
		length:0,
		isNull:false,
		isPK:false,
		defaultValue:"",
		descriptions:"",
		isLookup:true,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		}

	];
  } 
  export  function fnTableRelationList ( ) {
	return [
		{id:1,
		relationName:"t_product_title_m_company_group",
		descriptions:"t_product_title.company_group_id= m_company_group.id",
		PerantTableId:2,
		PerantTableName:"t_product_title",
		parentColumnId:"company_group_id",
		parentColumnName:1,
		childTableId:1,
		childTableName:"company_group",
		childColumnId:1,
		childColumnName:"id",
		isLock:true,
		isExecuted:false,
		isActive:true,
		isDeleted:false,
		createdBy:"surendra.kushwaha.contractor@macmillan.com",
		updatedBy:"surendra.kushwaha.contractor@macmillan.com",
		createdOn:"2023-08-29T12:39:05.950462",
		updatedOn:"2023-08-30T12:39:05.950462",
		}, 
	];
  
} 
export  function fnScreenNameList ( ) {
	return [ "Title Manager",
		"Business Partner",
		"Production",
		"Metadata",
		"Group Metadata",
		"Setup Module",
		"Users",
		"Roles",
		"Permissions",
	].sort();
  
} 