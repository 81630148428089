import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/login/components/login.component';
import { ResetPasswordComponent } from './features/resetpassword/components/reset-password/reset-password.component';
import { AuthService, authGuard } from './core/services/auth.service';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent
  },
  { 
    path: 'resetpassword', 
    component: ResetPasswordComponent 
  },
  {
    path: '',
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule)
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'isbninventory',
        loadChildren: () => import('./features/isbninventory/isbn-inventory.module').then((m) => m.IsbnInventoryModule)
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'product',
        loadChildren: () => import('./features/product/product.module').then((m) => m.ProductModule)
      }
    ]
  },{
    path: '',
    //canActivate:[authGuard],
    children: [
      {
        path: 'author',
        loadChildren: () => import('./features/author/author.module').then((m) => m.AuthorModule)
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'datafeed',
        loadChildren: () => import('./features/datafeed/datafeed.module').then((m) => m.DatafeedModule)
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'production',
        loadChildren: () => import('./features/production/production.module').then((m) => m.ProductionModule)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
