import { Component } from '@angular/core';

@Component({
  selector: 'app-bs-footer',
  templateUrl: './bs-footer.component.html',
  styleUrls: ['./bs-footer.component.css']
})
export class BsFooterComponent {

}
