import { Component } from '@angular/core';
import { SharedService } from '../shared.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-bs-sidebar',
  templateUrl: './bs-sidebar.component.html',
  styleUrls: ['./bs-sidebar.component.css']
})
export class BsSidebarComponent {
  isToggleSidebar: boolean = false;
  linkViewAccess : any =[];

  constructor(
    public sharedService: SharedService,
    public authService: AuthService
  ) {  }

  ngOnInit(): void {
    this.getAccessPermissioins();
  }

  toggleSideBar() {
    this.isToggleSidebar = this.isToggleSidebar ? false : true;
  }

  getAccessPermissioins(){
    if(this.authService.userPermisions?.length<1)
      this.authService.loadLoginUserPermissions();
    this.linkViewAccess = this.authService.userPermisions.filter((p:any) => p.access.includes('view')).map((x:any) => {return x.module});
  } 

  isLinkVisible(module:string){
    if(!this.authService.Permisions||this.authService.Permisions?.length <1)
      return false;
    if(this.authService.Permisions.includes('all'))
      return true;
    else if(this.authService.Permisions.includes('view'))
      return true;
    else if(this.authService.Permisions.includes(module+'.all') || this.authService.Permisions.includes(module+'.view') )
      return true;
    return false;
  }
}
