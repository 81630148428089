<div>
    <div class="flex-nowrap h-auto min-vh-93">
        <div class="col bg-light border-bottom">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start justify-content-md-start justify-content-sm-start p-3">
                <div class="col-12 col-lg-6 col-md-6 col-sm-9 mb-2 mb-md-0 d-flex">
                    <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none">
                        <img src="assets/images/macmillan_logo.png" alt="" data-atf="1" data-frt="0">
                    </a>    
                    <span class="fs-6 ms-2">Macmillan Learning - Title Management</span>
                </div>
            </div>
            
        </div>
        <div class="col py-2 border-bottom ps-3">
            <h4>
                Reset Password :
                <span *ngIf="userData">{{userData.fullname}}</span>
            </h4>
        </div>
        <div class="row align-items-center me-0 min-vh-74">
            <div class="mx-auto col-10 col-lg-6 col-md-8 col-sm-8">
                <h3>Enter New Password</h3>
                <form [formGroup]="resetForm">
                    <div class="mb-3">
                        <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control" 
                        (input)="onChangeNewPassword($event)" placeholder="Enter new password here">
                        <div class="mt-2 text-danger">
                            <span *ngIf="errorNewPasswordMessage" class="errorMessage"><i class="bi bi-x-circle"></i>{{errorNewPasswordMessage}}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <input [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" class="form-control" 
                        (input)="onChangeConfirmPassword($event)" required placeholder="Re-type new password here">
                        <div class="mt-2 text-danger">
                            <span *ngIf="errorConfirmPasswordMessage" class="errorMessage"><i class="bi bi-x-circle"></i>{{errorConfirmPasswordMessage}}</span>
                        </div>
                    </div>
                    <div class="mt-4 pt-2 d-flex align-items-center">
                        <div class="col-6 col-lg-8 col-md-7 col-sm-7">
                            <button type="submit" class="btn btn-primary" [disabled]="disableButton()" (click)='resetPassWord()'>Update</button>
                            <button type="submit" class="btn btn-secondary ms-3" (click)="cancel()">Cancel</button>
                        </div>
                        <div class="col-6 col-lg-4 col-md-5 col-sm-5 d-flex justify-content-end btn-group btn-group-lg btn-group-sm" role="group">
                            <button type="button" class="btn btn-light"(click)="hide = !hide">
                                <i class="bi" [ngClass]="hide? 'bi-eye-slash' : 'bi-eye'" (click)="hide = !hide"></i>
                                Show password on screen
                            </button>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-bs-footer></app-bs-footer>