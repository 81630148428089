import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { GlobalConstants } from 'src/app/core/services/globalconstants.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { GroupMetadata, Metadata } from '../models/metata.model';
import{ FormSettingData} from 'src/app/shared/metadata-group/metadata-group.component'

@Injectable({
  providedIn: 'root'
})
export class MetadataService{

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private globalConstant: GlobalConstants,

    public route: ActivatedRoute,
    private router: Router
  ) { }
  generateErrorMessages(form:FormGroup) {
    let formArray: any=[form];
    let verror:Array<string>=[];
    verror=[];
    formArray.forEach(function(_formControl:any){
       Object.keys(_formControl.controls).forEach(formElement => {
          const formInnerControl = _formControl.get(formElement);
       if (formInnerControl && formInnerControl.errors) {
            verror.push(`${formElement} is required`);
       }
       // Handle 'minlength' case
      if (formInnerControl.errors && formInnerControl.errors.minlength) {
          verror.push(`${formElement} minimum length is ${formInnerControl.errors.minlength.requiredLength}.`);
       }

        });
   });
  return verror.join('\n');
 }
  
   getMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATALIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))),
      this.baseService.getHeaders());
  }
  getMetadataDetail(id: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATADETAILS_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  postCreateMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATACREATE_URL),
     metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }

  putUpdateMetadata(metadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATAUPDATE_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATADELETE_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  restoreMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATARESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  //-------------------group metadata
  getGroupMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPLIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))),
      this.baseService.getHeaders());
  }
  getGroupMetadataDetail(id: Number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPDETAIL_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  getGroupMetadataDetailByCode(groupcode: string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPDETAILBYCODE_URL
      .replace('#groupcode', groupcode)),
      this.baseService.getHeaders());
  }
  postCreateGroupMetadata(groupMetadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATAGROUPCREATE_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  putUpdateGroupMetadata(groupMetadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATAGROUPUPDATE_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteGroupMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPDELETE_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  restoreGroupMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPRESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  getMetadataSuggestionList(searchText: string) : Observable<any[]> {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATYPEAHEADSEARCH_URL
      .replace('#searchText', searchText)),
      this.baseService.getHeaders()).pipe(
        map(response =>
          {
            return response?.results;

          })
      );
   }
   getGroupMetadataSuggestionList(searchText: string) : Observable<any[]> {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAGROUPTYPEAHEADSEARCH_URL
      .replace('#searchText', searchText)),
      this.baseService.getHeaders()).pipe(
        map(response =>
          {
            return response?.results;

          })
      );
   }
    //-------------------modulesetup metadata
  getModuleSetupMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPLIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))),
      this.baseService.getHeaders());
  }
  getModuleSetupMetadataDetail(id: Number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPDETAIL_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  getModuleSetupMetadataListByScreenName(screenname: string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPDELISTBYSCREENNAME_URL
      .replace('#screenname', screenname)),
      this.baseService.getHeaders());
  }
  deleteModuleSetupMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPDETETE_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  restoreModuleSetupMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPRESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  postCreateModuleSetupMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPCREATE_URL),
    metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  putUpdateModuleSetupMetadata(metadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATAMODULESETUPUPDATE_URL),
    metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(() => error);
  }

  //------get Form Setting Data from Group Metadata
  getFormSettingDatafromGroupMetadata(groupMetadataDetail:GroupMetadata|null):FormSettingData|null{
   var formSettingData: FormSettingData;
      var controls:Array<any>=[]
    groupMetadataDetail?.metadataDbMappingList?.forEach(el => {
      controls.push(
        { name: el.metadataDetail.name,
          label: el.metadataDetail.label,
          value: el.metadataDetail.value,
          type: el.metadataDetail.type,
          options: { min:String( el.metadataDetail.options.min),
            max:String( el.metadataDetail.options.max),
            step:String( el.metadataDetail.options.step),
            icon:String( el.metadataDetail.options.icon)},
          required: el.metadataDetail.validators.required,
          validators:  {//min: el.metadataDetail.validators.minLength
            //,max: el.metadataDetail.options.max,
            required: el.metadataDetail.validators.required
            //,requiredTrue: el.metadataDetail.validators.required,
            //,email: false,
            ,minLength: el.metadataDetail.validators.minLength
            //,maxLength: el.options.max>0
            //,pattern: el.value   
            //,nullValidator: el.validators.required
            }
          })
    });
    formSettingData={controls:controls};
    return formSettingData;
  }
  //------------------------Table Metadata
  getTableMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLELIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))),
      this.baseService.getHeaders());
  }
  getTableMetadataDetail(id: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLEDETAILS_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  postCreateTableMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATATABLECREATE_URL),
     metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }

  putUpdateTableMetadata(metadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATATABLEUPDATE_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteTableMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLEDELETE_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  restoreTableMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  lockMetadataTable(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLELOCK_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  unLockMetadataTable(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLEUNLOCK_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  validateMetadataTable(metadata: any) {
    return this.httpClient.post( this.baseService.GET_URL(this.globalConstant.METADATATABLEVALIDATE_URL),
      metadata,
       {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
       );
   }
 
  postExecuteTableScripeMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATATABLEEXECUTESCRIPT_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  //------------------------Table Column Metadata
  getTableColumnsMetadata(tableid: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNS_URL
      .replace('#tableid', String(tableid)) ),
      this.baseService.getHeaders());
  }
  getTableColumnMetadataDetail(id: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNDETAILS_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  postCreateTableColumnMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNCREATE_URL),
     metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }

  putUpdateTableColumnMetadata(metadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNUPDATE_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteTableColumnMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNDELETE_URL
      .replace('#id', String(id))),
      this.baseService.getHeaders());
  }
  restoreTableColumnMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNRESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  getColumnMetadataSuggestionList(searchText: string) : Observable<any[]> {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNTYPEAHEADSEARCH_URL
      .replace('#searchText', searchText)), 
      this.baseService.getHeaders()).pipe(
        map(response => 
          {
            return response?.results;
               
          })
      );
   }

  postExecuteColumnScripeMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATATABLECOLUMNEXECUTESCRIPT_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  //-------------table relation
  getTableRelationMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONLIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))), 
      this.baseService.getHeaders());
  }
  getTableRelationMetadataDetail(id: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONDETAILS_URL
      .replace('#id', String(id))), 
      this.baseService.getHeaders());
  }
  postCreateTableRelationMetadata(metadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONCREATE_URL),
     metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
 
  putUpdateTableRelationMetadata(metadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONUPDATE_URL),
      metadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteTableRelationMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONDELETE_URL
      .replace('#id', String(id))), 
      this.baseService.getHeaders());
  } 
  restoreTableRelationMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONRESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  validateMetadataTableRelation(metadata: any) {
    return this.baseService.POST(this.globalConstant.METADATATABLERELATIONSCRIPTVALIDATE_URL, metadata).pipe(map((response) => response));
  } 
  createMetadataTableRelationScript(metadata: any) {
    return this.baseService.POST(this.globalConstant.METADATATABLERELATIONSCRIPTCREATE_URL, metadata).pipe(map((response) => response));
  }
  alterMetadataTableRelationScript(metadata: any) {
    return this.baseService.POST(this.globalConstant.METADATATABLERELATIONSCRIPTALTER_URL, metadata).pipe(map((response) => response));
  }
  lockMetadataTableRelation(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONLOCK_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  unLockMetadataTableRelation(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.METADATATABLERELATIONUNLOCK_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  //-------------------group metadata form submit data save dynamically to given target db table
  postCreateGroupDbMetadata(groupMetadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.GROUPDBMETADATACREATE_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  putUpdateGroupDbMetadata(groupMetadata: any) {
    return this.httpClient.put(this.baseService.GET_URL(this.globalConstant.GROUPDBMETADATAUPDATE_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  getPostGroupDbMetadataDetail(groupMetadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.GROUPDBMETADATADETAIL_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  getGroupDbMetadataDetailByGcPidId(gc:string,pid:number,id:number=0) {
    return this.httpClient.get(this.baseService.GET_URL(this.globalConstant.GROUPDBMETADATADETAILBY_GC_PID_ID_URL
      .replace('#gc', gc)
      .replace('#pid', String(pid))
      .replace('#id', String(id))), 
    
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  getPostGroupDbMetadataList(groupMetadata: any) {
    return this.httpClient.post(this.baseService.GET_URL(this.globalConstant.GROUPDBMETADATALIST_URL),
    groupMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  //-----------help
  getHelpMetadataList(page: number, pageSize: number, sortField: string, sortOrder: number,searchText:string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATALIST_URL
      .replace('#page', String(page))
      .replace('#pageSize', String(pageSize))
      .replace('#sortField', sortField)
      .replace('#sortOrder', String(sortOrder))
      .replace('#searchText', String(searchText))), 
      this.baseService.getHeaders());
  }
  getHelpMetadataDetail(id: number) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATADETAIL_URL
      .replace('#id', String(id))), 
      this.baseService.getHeaders());
  }
  postCreateHelp(helpMetadata:any){
    return this.httpClient.post<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATACREATE_URL),
    helpMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  putUpdateHelp(helpMetadata:any){
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATAUPDATE_URL),
    helpMetadata,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'}
      );
  }
  deleteHelpMetadataDetail(id: number) {
    return this.httpClient.delete<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATADELETE_URL
      .replace('#id', String(id))), 
      this.baseService.getHeaders());
  }
  putRestoreHelpMetadataDetail(id: number) {
    return this.httpClient.put<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATARESTORE_URL
      .replace('#id', String(id))), null,
      {headers: this.baseService.getHttpHeaders(), responseType: 'json'});
  }
  getHelpMetadataByScreenViewTab(screenName: string, viewName: string, tabName: string) {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.HELPMETADATADETAILSBY_SC_VW_TB_URL
      .replace('#sc', String(screenName)) 
      .replace('#vw', String(viewName))
      .replace('#tb', String(tabName)) ), 
      this.baseService.getHeaders());
  }  
}
