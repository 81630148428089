<div class="container-fluid">
    <div class="row d-flex flex-wrap">
        <div class="col p-2 bg-light border-bottom">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <div class="col-6 col-lg-6 col-md-6 col-sm-6 mb-2 mb-md-0 d-flex align-items-center">
                    <a class="nav-link" role="button" (click)="toggleSideBar()">
                        <i class="fs-3 bi bi-grid-3x3-gap-fill"></i>
                    </a>
                    <!-- <a href="/" class="d-flex mb-2 mb-lg-0 link-body-emphasis text-decoration-none ms-3">
                        <img src="assets/images/header_logo.png" alt="" data-atf="1" data-frt="0" height="45px">
                    </a>   -->
                    <img src="assets/images/header_logo.png" alt="" data-atf="1" data-frt="0" height="45px" class="d-flex mb-2 mb-lg-0 link-body-emphasis text-decoration-none ms-3">  <!--TMS-146-->
                </div>              
                <div class="col-6 col-lg-6 mb-3 col-md-6 col-sm-6 mb-lg-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-center">
                    <!-- <div class="text-danger align-middle d-none" *ngIf="(timeRemaining$  | async) as t; else sorry">
                        <label> Session will expire in </label>
                        <span id="days"> {{t.daysToDday}} </span>Day(s)
                        <span id="hours"> {{t.hoursToDday}} </span>Hrs
                        <span id="minutes"> {{t.minutesToDday}} </span>Min
                        <span id="seconds"> {{t.secondsToDday}} </span>S
                    </div>
                    
                    <ng-template #sorry>
                         
                    </ng-template> -->
                    <div>
                        <button class="btn btn-sm" (click)="op.toggle($event)" label="Isbn" pBadge value={{lockedList?.total}} ngbTooltip="Isbn"> <i class="fs-5 fa-solid fa-lock"></i></button>  
                        <p-overlayPanel #op [style]="{'width': '200px'}" [showCloseIcon]="true">
                            <ng-template pTemplate="content">
                                <p-table [value]="lockedList?.result" responsiveLayout="scroll">
                                    <ng-template pTemplate="header">
                                        <tr>
                                           <th>ISBN</th>                      
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-lockedList>
                                        <tr [pSelectableRow]="rowData">
                                            <td>{{lockedList.isbn13}}</td>                        
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </ng-template>
                        </p-overlayPanel>            
                    </div>
                    <div class="dropdown open">
                        <a class="btn border-none outline-none dropdown-toggle" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="fs-5 fa fa-circle-user"></i>
                            <span class="ms-1 d-none d-sm-inline">{{name}}</span>
                        </a>
                        <ul class="dropdown-menu text-small">
                            <!-- <li>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="fs-6 fa fa-gear"></i>
                                    <span class="ms-2 d-sm-inline">Settings</span>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item disabled" href="#">
                                    <i class="fs-6 fa fa fa-user"></i>
                                    <span class="ms-2 d-sm-inline">Profile</span>
                                </a>
                            </li>
                            <li><hr class="dropdown-divider"></li> -->
                            <li>
                                <a class="dropdown-item" role='button' (click)="signOut()">
                                    <i class="bi bi-box-arrow-left"></i>
                                    <span class="ms-2 d-sm-inline">Sign out</span>
                                </a>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <a class="dropdown-item" role='button' (click)="onGotoUserGuide()">
                                <i class="fa fa-book"></i>
                                <span class="ms-2 d-sm-inline">User Guide</span>
                            </a>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>