<button type="button" class="btn btn-primary btn-sm  ms-2" (click)="openHelpModal(showHelpModal)" title="Help">
    <i class="fs-6 fa fa-question-circle"></i>
    <span class="ms-2 d-sm-inline d-none">Help</span>
</button>
<ng-template #showHelpModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Help Details  </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()">
        </button>
    </div>
    <div class="modal-body"> 
        <div class="row mb-3 small fst-italic">
            <label>possible help for current view</label>
        </div>
        <fieldset class="border p-2">
         <legend  class="float-none w-auto">{{screenName}} > {{viewName}} > {{tabName}}</legend>
            <form [formGroup]="helpForm" class="form-inline" > 
                <div class="form-group">  
                    <angular-editor formControlName="htmlHelpDetails" [config]="editorConfig" ngDefaultControl  placeholder="Help text..."  ></angular-editor>  
                    <!-- <div class="popover-html-content-body" [innerHTML]="htmlHelpDetails"></div> -->
                </div> 
            </form>                  
        </fieldset>  
    </div>
    <div class="modal-footer justify-content-center">
         
        <button type="submit" class="btn btn-primary" (click)="closeModel()">Close</button>
    </div>
 </ng-template>