import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { GlobalConstants } from 'src/app/core/services/globalconstants.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isToggleSidebar: boolean = false;
  
  constructor(private httpClient: HttpClient,
    private baseService: BaseService,
    private globalConstant: GlobalConstants) { }

  getIsbnLockedList(currentUserId: number)
  {
    return this.httpClient.get<any>(this.baseService.GET_URL(this.globalConstant.ISBNLOCKEDLIST_URL + currentUserId), this.baseService.getHeaders());
  }
}
