import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn:'root'
})
export class EventService {

  constructor(private http:HttpClient) { }

  fetchT(){
       return this.http.get('---api call to use refresh token to generate new token------');
  }

}