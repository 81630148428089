import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfile } from 'src/app/features/admin/models/admin.model';
import { AdminService } from 'src/app/features/admin/services/admin.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup = new FormGroup({
    password: new FormControl(),
    confirmPassword: new FormControl()
  });
  errorNewPasswordMessage!: string;
  errorConfirmPasswordMessage!: string;
  validationToken: string = "";
  userData!: UserProfile;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      if (res['validationToken']) {
        this.validationToken = res['validationToken'];
        this.adminService.redirectToPasswordReset(this.validationToken)
        .subscribe( {next: (response) => {
          if(response) {
            if (response.toString().split('##')[0] == 'false') {
                this.router.navigateByUrl('/login');
                return;
            } else {
                let userName = response.toString().split('##')[1];
                if(response.toString().split('##')[0])
                this.getUserDeatils(userName, this.validationToken);
            }
          }
        },
        error: (error) => {
          console.log("error :" + error.message);
        }});
      } 
      else
        this.getUserDeatils(res['username'], "");
    });

    this.resetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;
    console.log(group);
    return pass === confirmPass ? null : { notSame: true };
  }

  disableButton() {
    if(this.resetForm) {
      if(!this.resetForm?.get('password')?.value ||
      !this.resetForm?.get('confirmPassword')?.value) {
          return true;
      }
      if(this.errorConfirmPasswordMessage!='' || this.errorNewPasswordMessage != ''){
        return true;
      }
   }
   return false;
  }

  cancel() {
    this.router.navigateByUrl('/login')
  }

  onChangeNewPassword(event: any) {
    this.errorNewPasswordMessage = '';
    var tests = [
      {
          pattern : /[0-9]/,
          errorMessage  : 'Should have at least one number'
      },
      {
          pattern : /[a-z]/,
          errorMessage  : 'Should have at least one lowercase letter'
      },
      {
          pattern : /[A-Z]/,
          errorMessage  : 'Should have at least one uppercase letter'
      }];

    if(event?.target?.value.length<8)
    {
      this.errorNewPasswordMessage = 'Invalid input, should have at least 8 characters';
      return;
    }

    if(event?.target?.value == '')
    {
      this.errorNewPasswordMessage =  'Invalid input, this field is required';
      return;
    }

    for(var i in tests){
      if(!tests[i].pattern.test(event?.target?.value)){
        this.errorNewPasswordMessage =  tests[i].errorMessage;
        return;
      }
  }
  if(this.resetForm?.get('confirmPassword')?.value.trim()!='')
    this.onChangeConfirmPassword(this.resetForm?.get('confirmPassword')?.value);
  }

  onChangeConfirmPassword(event: any) {
    this.errorConfirmPasswordMessage = '';
    if(event?.target?.value != this.resetForm?.get('password')?.value)
    {
      this.errorConfirmPasswordMessage =  'Password does not match';
      return;
    }
  }

  getUserDeatils(adminData: string, token: string) {
    if (adminData !== undefined && adminData !== '') {
      this.adminService.getUserDetails(adminData, token)
      .subscribe( {next: (response) => {
        this.userData = response.userdetails;
        this.resetForm.setValue({Password: '', confirmPassword: ''});
      },
      error: (error) => {
        console.log("error :" + error.message);
      }});
    }
  }

  resetPassWord() {
    let resetParams = {
      newPassword: this.resetForm.controls['password'].value,
      oldPassword: '',
      username: this.userData?.username,
      token: this.validationToken
    };
    this.adminService.resetPassword(resetParams)
    .subscribe( {next: (response) => {
        this.router.navigateByUrl('/login')
    },
    error: (error) => {
      console.log("error :" + error.message);
    }});
  }
}
