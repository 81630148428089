import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginUser: any;
  loginUserRoles: any;
  Permisions: any = [];
  userPermisions: any = [];
  actions : any = ['all','view', 'create','add','edit','delete','upload']; // to be relaced by service 
  constructor(private util: UtilService, private router: Router) { }

  canActivate(state: any): boolean {
    var path  = state.url.slice(1).split('/');
    var action = (path.length>1 && this.actions.includes(path[path.length-1]))? (path[path.length-1]).replace("add", "create") : 'view';
    //var routeName = state.url.slice(1).substring(0,state.url.lastIndexOf('/')-1).replace('/','.');
    var routeName = path.filter((p: any)=> !this.actions.includes(p)).map((x: any)=>x).join('.');
    return this.isPermissionExists(routeName,action);
  }

  loadLoginUserPermissions(){
    this.loadLoginUser();
    this.userPermisions = [];
    var user = this.loginUser;
    if(!this.util.isNullOrEmpty(user) && user.permissions){
      var modules = ([...new Set(user.permissions.map((p:any) => p.name.substring(0,p.name.lastIndexOf('.'))))])?.sort();
      //if(!this.util.isNullOrEmpty(modules)) {
      if(modules?.length>0) {
        modules.forEach(md => {
          var access = user.permissions.filter((ac: any) => md == ac.name.substring(0,ac.name.lastIndexOf('.'))).map((a:any) => a.name.substring(a.name.lastIndexOf('.')+1));
          this.userPermisions.push({'module': md, 'access':access});          
        });
        //console.log(this.userPermisions);
      }
    }
  }

  isPermissionExists(routeName: string, action: string): boolean{ 
    var isvalid = false;
    if(this.userPermisions){
      isvalid = this.userPermisions.findIndex((pr: any) => pr.module == routeName && pr.access.includes(action)) > -1;
    }
    return isvalid;

  }

  isValidPermission(permission : string): boolean{
    return false;
  }

  isValid(module: string, access: string){
    return this.userPermisions.find((x:any) => x.module == module && x.access.includes(access))?.length>0;
  }

  loadLoginUser(){
    const userJson = localStorage.getItem('tms.user_details');
    this.loginUser = (userJson != null) ? JSON.parse(userJson) : undefined;
    this.loginUserRoles = (this.loginUser?.roles)? this.loginUser?.roles : undefined;
    this.Permisions = (this.loginUser?.permissions)? [...new Set(this.loginUser.permissions.map((p:any) => p.name))] : undefined;
    console.log(this.Permisions);
  }
}

export const authGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {   
      return inject(AuthService).canActivate(state);
};
