<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <div class="d-flex flex-column justify-content-between col-auto min-vh-90">
        <div>
            <ul class="nav nav-pills flex-column mt-3 mt-sm-0" id="menu">
                <!-- <li class="nav-item active my-sm-1 my-2">
                    <a [routerLink]="['/dashboard']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Dashboard">
                        <i class="fs-6 fa fa-gauge"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Dashboard</span>
                    </a>
                </li> -->
                <li class="nav-item my-sm-1 my-2" routerLinkActive="active">
                    <a [routerLink]="['/isbninventory']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="ISBN Inventory" *ngIf="isLinkVisible('isbn')">
                        <i class="fs-6 fa fa-barcode"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">ISBN Inventory</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2" routerLinkActive="active">
                    <a [routerLink]="['/product']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Title Manager" > <!--*ngIf="isLinkVisible('product')"-->
                        <i class="fs-6 fa fa-rectangle-list"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Title Manager</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2" routerLinkActive="active">
                    <a [routerLink]="['/production']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Production" *ngIf="isLinkVisible('production')">
                        <i class="fs-6 fa fa-print"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Production</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2" routerLinkActive="active">
                    <a [routerLink]="['/author']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Business Partner" *ngIf="isLinkVisible('author')"> <!--*ngIf="linkViewAccess.includes('author')" *ngIf="isValid('author')"-->
                        <i class="fs-6 fa fa-users"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Business Partner</span>
                    </a>
                </li>
                <!-- <li class="nav-item my-sm-1 my-2">
                    <a href="#" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Orders">
                        <i class="fs-6 fa fa-cart-shopping"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Orders</span>
                    </a>
                </li> -->
                <li class="nav-item my-sm-1 my-2 dropdown" routerLinkActive="active">
                    <!-- <a class="nav-link text-white dropdown-toggle" aria-current="page" id="navbarDataFeed" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbTooltip="Data Feeds">
                        <i class="fs-6 fa fa-feed"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Data Feeds</span>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDataFeed">
                        <a class="dropdown-item" [routerLink]="['/datafeed/channel']" ngbTooltip="Channels">Channels</a>
                        <a class="dropdown-item" [routerLink]="['/dashboard']" ngbTooltip="Inbound Feeds">Inbound Feeds</a>
                    </ul> -->
                    <a [routerLink]="['/datafeed/channel']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Data Feeds" *ngIf="isLinkVisible('datafeeds')">
                        <i class="fs-6 fa fa-feed"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Data Feeds</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2" routerLinkActive="active">
                    <a [routerLink]="['/admin']" class="nav-link text-white" aria-current="page" placement="end" ngbTooltip="Admin" *ngIf="isLinkVisible('admin')">
                        <i class="fs-6 fa fa-user-cog"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">Admin</span>
                    </a>
                </li>
                <!-- <li class="nav-item my-sm-1 my-2 dropdown">
                    <a class="nav-link text-white dropdown-toggle" aria-current="page" id="navbarDdmlAdmin" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" title="User Management">
                        <i class="fs-6 fa fa-user"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="this.sharedService.isToggleSidebar ? 'd-none': 'd-xl-none'">User Management</span>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDdmlAdmin">
                        <a class="dropdown-item" href="#" title="User Groups">User Groups</a>
                        <a class="dropdown-item" [routerLink]="['/admin/users']" title="Users">Users</a>
                        <a class="dropdown-item" href="#" title="Roles">Roles</a>
                        <a class="dropdown-item" href="#" title="Permissions">Permissions</a>
                    </ul>
                </li> -->
            </ul>
        </div>
        <!-- <div>
            <div class="dropdown open">
                <a class="btn border-none outline-none text-white dropdown-toggle" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                            <i class="fs-5 fa fa-circle-user"></i>
                            <span class="ms-1 d-none d-sm-inline">Manasranjan</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="triggerId">
                    <a class="dropdown-item" href="#">
                        <i class="fs-6 fa fa fa-user"></i>
                        <span class="ms-2 d-sm-inline">Profile</span>
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fs-6 fa fa-gear"></i>
                        <span class="ms-2 d-sm-inline">Settings</span>
                    </a>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-box-arrow-left"></i>
                        <span class="ms-2 d-sm-inline"></span>Sign out
                    </a>
                </div>
            </div>
        </div> -->
    </div>
</div>
