<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <div class="d-flex flex-column justify-content-between col-auto min-vh-88">
        <div>
            <ul class="nav nav-pills flex-column mt-2 mt-sm-0" id="menu">
                <a class="nav-link text-white" role="button" (click)="toggleSideBar()">
                    <i class="fs-5 fa fa-bars"></i>
                </a>
                <li class="nav-item active my-sm-1 my-2">
                    <a [routerLink]="['/dashboard']" class="nav-link text-white" aria-current="page">
                        <i class="fs-6 fa fa-gauge"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="isToggleSidebar ? 'd-none': 'd-xl-none'">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2">
                    <a [routerLink]="['/admin/users']" class="nav-link text-white" aria-current="page">
                        <i class="fa-6 fa fa-users"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="isToggleSidebar ? 'd-none': 'd-xl-none'">Users</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2">
                    <a href="#" class="nav-link text-white" aria-current="page">
                        <i class="fs-6 fa fa-table"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="isToggleSidebar ? 'd-none': 'd-xl-none'">Roles</span>
                    </a>
                </li>
                <li class="nav-item my-sm-1 my-2">
                    <a href="#" class="nav-link text-white" aria-current="page">
                        <i class="fs-6 fa fa-table"></i>
                        <span class="ms-2 d-xl-inline" [ngClass]="isToggleSidebar ? 'd-none': 'd-xl-none'">Permissions</span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- <div>
            <div class="dropdown open">
                <a class="btn border-none outline-none text-white dropdown-toggle" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                            <i class="fs-5 fa fa-circle-user"></i>
                            <span class="ms-1 d-none d-sm-inline">Manasranjan</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="triggerId">
                    <a class="dropdown-item" href="#">
                        <i class="fs-6 fa fa fa-user"></i>
                        <span class="ms-2 d-sm-inline">Profile</span>
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fs-6 fa fa-gear"></i>
                        <span class="ms-2 d-sm-inline">Settings</span>
                    </a>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item" href="#">
                        <i class="bi bi-box-arrow-left"></i>
                        <span class="ms-2 d-sm-inline"></span>Sign out
                    </a>
                </div>
            </div>
        </div> -->
    </div>
</div>
