<p-toast></p-toast>
<form [formGroup]="fileUploadForm">  
    <input type="file" #file class="form-control" formControlName="files" accept="acceptFileExtesions" (change)="onFileBrowse($event,viewFileUploadProgressModal)"   placeholder="Choose file" style="display:none;">
    <button type="button" class="btn btn-primary" (click)="file.click()">
        <i class="fs-6 fa-solid fa-upload"></i>
        <span class="ms-2 d-none d-sm-inline">Choose File</span>
    </button>
  </form>
  <ng-template #viewFileUploadProgressModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Media Upload Status</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()">
        </button>
    </div>
    <div class="modal-body">
      <div class="media-upload-table-container" *ngIf="uploadedMedia.length > 0">
        <table class="media-upload-table table table-borderless">
          <thead>
            <tr>
              <th style="width: 246px"></th>
              <th class="media-progress-bar"></th>
              <th style="width: 100px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let media of uploadedMedia; let i = index">
              <td>
                <div class="d-flex flex-row align-items-left">
                  <!-- <div style="margin-right: 8px;">
                      <img  class="add-media-img" src="{{media.FileUrl}}">
                    </div> -->
                  <div class="media-file-name">
                    <span style="word-wrap: break-word; white-space: pre-line">
                      {{ media.FileName }}
                    </span>
                  </div>
                </div>
              </td>
              <td style="vertical-align:middle;">
                <div class="d-flex flex-column" style="margin-top: 18px;">
                  <div>
                    <div class="first-progress">
                      <div
                        [ngStyle]="{ 'width.%': media.FileProgress }"
                        class="second-progress"
                      ></div>
                    </div>
                  </div>
                  <div class="text-center">
                    {{ media.FileProgessSize }} of {{ media.FileSize }}
                  </div>
                </div>
              </td>
              <td style="vertical-align:middle;text-align: left;">
                <div class="media-upload-check">
                  <span *ngIf="media.FileProgress === 100"> Completed</span>
                </div>
                <div class="media-upload-check">
                  <span *ngIf="media.IsFileUploaded === false">[ {{media.ServerMessage}}]</span>
                </div>
              </td>
              <td style="vertical-align:middle;">
                <a class="remove-media-txt" (click)="removeImage(i)"> Remove </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
 </ng-template>
