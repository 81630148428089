import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule ,NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect'
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { PickListModule } from 'primeng/picklist';
import { StepsModule } from 'primeng/steps';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RouterModule } from '@angular/router';
import { BsHeaderComponent } from './bs-header/bs-header.component';
import { BsFooterComponent } from './bs-footer/bs-footer.component';
import { BsSidebarComponent } from './bs-sidebar/bs-sidebar.component';
import { BsSidebarAdminComponent } from './bs-sidebar-admin/bs-sidebar-admin.component';
import { NgbTableComponent } from './ngb-table/ngb-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadComponent } from './fileupload/file-upload.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { TreeModule } from 'primeng/tree';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import {  AngularEditorModule  } from '@kolkov/angular-editor';
import { FileUploadModule } from 'primeng/fileupload';
import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { MetadataGroupComponent } from './metadata-group/metadata-group.component';
import{ htmlToPlaintextPipe} from '../pipes/html-to-plaintext.pipe';
import { CalendarModule } from 'primeng/calendar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeTableModule } from 'primeng/treetable';
import { ShowHelpComponent } from './show-help/show-help.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    BsHeaderComponent,
    BsFooterComponent,
    BsSidebarComponent,
    BsSidebarAdminComponent,
    NgbTableComponent,
    FileUploadComponent,
    MetadataGroupComponent,
    JsonViewerComponent,
    htmlToPlaintextPipe,
    ShowHelpComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    DataViewModule,
    ToastModule,
    PickListModule,
    DragDropModule,
    StepsModule,
    ChipsModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    BadgeModule,
    TreeModule,
    AutoCompleteModule,
    CheckboxModule,
    AngularEditorModule,
    FileUploadModule,
    CalendarModule,
    TieredMenuModule,
    TreeTableModule,
    DialogModule
  ],
  exports: [
    BsHeaderComponent,
    BsFooterComponent,
    BsSidebarComponent,
    BsSidebarAdminComponent,
    NgbModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CommonModule,
    NgbTableComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    DataViewModule,
    ToastModule,
    PickListModule,
    DragDropModule,
    StepsModule,
    ChipsModule,
    TreeModule,
    OverlayPanelModule,
    BadgeModule,
    ConfirmDialogModule,
    FileUploadComponent,
    MetadataGroupComponent,
    AutoCompleteModule,
    CheckboxModule,
    AngularEditorModule,
    FileUploadModule ,
    JsonViewerComponent,
    htmlToPlaintextPipe,
    CalendarModule,
    TieredMenuModule,
    TreeTableModule,
    ShowHelpComponent,
    DialogModule
  ]
})
export class SharedModule { }
