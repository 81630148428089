import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { GlobalConstants } from 'src/app/core/services/globalconstants.service';
import { UserProfileModel } from '../models/admin.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    public http: HttpClient,
    private baseService: BaseService,
    private globalConstants: GlobalConstants 
  ) { }

  getForgotPassword(userName: string) {
    return this.http.get(this.baseService.GET_URL(this.globalConstants.FORGOTPASSWORD_URL).replace('#username',userName), {responseType: 'text'});
  }

  redirectToPasswordReset(token: any) {
    var data = { "validationToken": token };
    
    return this.http.post(this.baseService.GET_URL(this.globalConstants.REDIRECTTOPASSWORD_URL),
      data,
      { headers: new HttpHeaders(), responseType: 'text'}
    );
  }

  resetPassword(resetParams: any) {
    return this.http.post(this.baseService.GET_URL(this.globalConstants.RESETPASSWORDWITHTOKEN_URL),
      resetParams,
      this.baseService.getHeaders()
    )
    .pipe(catchError(this.handleError));
  }

  getUserDetails(userName: string, token: string = "") {
    var params = {
      username: userName,
      token: token
    }
    return this.http.post<UserProfileModel>(this.baseService.GET_URL(this.globalConstants.USERBASICDETAILS_URL),
      params
    );
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(() => error);
  }
  postUploadPubDate(formFile: any) {
    return this.postBatchUpload(formFile,this.globalConstants.PUBDATEBATCHUPLOAD_URL);
  }

  postUploadProductPrice(formFile: any) {
    return this.postBatchUpload(formFile,this.globalConstants.PRODUCTPRICEBATCHUPLOAD_URL);
  }

  postBatchUpload(formFile: any,path:string) {
    //return this.http.post(this.baseService.GET_URL(this.globalConstants.PUBDATEBATCHUPLOAD_URL),formFile,{headers: this.baseService.getHttpHeaders(), responseType: 'json'} );
    return this.http
    .post(this.baseService.GET_URL(path), formFile, {headers: this.baseService.getUploadHeaders() , reportProgress: true, observe: 'events'})
      .pipe(
        map((event:any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }
}
