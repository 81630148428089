import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ngb-table',
  templateUrl: './ngb-table.component.html',
  styleUrls: ['./ngb-table.component.css']
})
export class NgbTableComponent implements OnInit {
	@Input() tableColumns: Array<any> = [];
	@Input() tableData: Array<any> = [];
	@Input() paginator: boolean = true;
	@Input() pageSize: number = 5;
	@Input() showCurrentPageReport: boolean = true;
	@Input() currentPageReportTemplate: string = "";
	@Input() rowsPerPageOptions: number[] = [5,10,25,50];
	@Input() totalRecords: number = 0;
	@Input() scrollHeight: string = "";
  	@Output() linkClickEvent = new EventEmitter();
	@Output() pageChangeEvent = new EventEmitter();
	tableSize: string = "p-datatable-sm";
	
	constructor() {
		
	}

	ngOnInit(): void {
		
	}

	loadData(event: any) {
		this.pageChangeEvent.emit(event);
	}

	onLinkClicked(data: any) {
		this.linkClickEvent.emit(data);
	}

	customSort(event: any) {
	}
}
