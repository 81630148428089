import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalConstants {

  constructor() { }
  keyDown:any={"ENTER":"Enter",
    "ESC":"Escape",
    "ALT":"Alt",
    "TAB":"Tab",
    "BACKSPACE":"Backspace",
    "CONTROL":"Control",
    "COMMAND":"Command",
    "ARROWUP":"ArrowUp",
    "ARROWDOWN":"ArrowDown",
    "ARROWLEFT":"ArrowLeft",
    "ARROWRIGHT":"ArrowRight",
    "F4":"F4"
  };
  // Login Module Url's
  TOKEN_URL = "oauth/token";
  USERDETAILS_URL = "account/details/#username";
  USERBASICDETAILS_URL = "account/basicdetails";
  FORGOTPASSWORD_URL = "account/resetpassword?email=#username";
  REDIRECTTOPASSWORD_URL = "/tmsapp/tmsapi/api/account/redirecttoresetpassword";
  RESETPASSWORDWITHTOKEN_URL = "account/resetpasswordwithtoken";

  // ISBN Inventory Module Url's
  ISBNFILEUPLOAD_URL = "isbninventory/isbnfileupload";
  ISBNLOCKEDLIST_URL = "isbninventory/isbnlockedlist?userId=";
  ISBNPURCHASELIST_URL = "isbninventory/isbnpurchaselist?startFrom=#startFrom&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  ISBNINVENTORYLIST_URL = "isbninventory/isbninventorylist?searchText=#searchText&status=#status&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  ISBNTYPEAHEADSEARCH_URL = "isbninventory/isbntypeaheadsearch?searchText=#searchText";
  ISBNSTATUSUPDATE_URL = "isbninventory/isbnstatusupdate?isbnKey=#isbnKey&status=#status&title=#title&userId=";
  USERISBNTYPEAHEADSEARCH_URL = "isbninventory/userisbntypeaheadsearch?username=#username&searchText=#searchText";

  // Product Module Url's
  PRODUCTLIST_URL = "product/productlist?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  PRODUCTLISTADVANCESEARCH_URL = "product/AdvanceSearchlist";
  PRODUCTDETAIL_URL = "product/productdetail?isbn=#isbn";
  CREATEPRODUCT_URL =  "product/createproduct";
  COPYPRODUCT_URL =  "product/copyproduct";
  PRODUCTISBNTYPEAHEADSEARCH_URL = "product/isbntypeaheadsearch?searchText=#searchText&filterFiled=#filterFiled";
  UPDATEPRODUCT_URL = "product/updateproduct";
  UPDATEPRODUCTPACKAGEFLAG_URL = "product/updateproductpackageflag";
  PRODUCTSPECIFICATION_URL = "product/productspecification?titleId=";
  UPDATEPRODUCTSPECIFICATION_URL = "product/updateproductspecification";
  PRODUCTAUTHORS_URL = "product/productauthors?titleId=#titleId";
  PRODUCTAUTHOR_URL = "product/productauthor?id=#id";
  CREATEPRODUCTAUTHORS_URL = "product/createproductauthors";
  UPDATEPRODUCTAUTHOR_URL = "product/updateproductauthor";
  UPDATEPRODUCTAUTHORS_URL = "product/updateproductauthors";
  DELETEPRODUCTAUTHOR_URL = "product/deleteproductauthor?id=#id";
  PRODUCTAUDITLOGDETAILS_URL = "product/auditdetails?auditForRecordId=#auditForRecordId&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  PRODUCTFAMILYTREE_URL = "product/productfamilytree?projectIsbn=";
  CLASSIFICATIONTYPEONELIST_URL = "product/classificationtypeonelist?categoryId=";
  CLASSIFICATIONSUBTYPETWOLIST_URL = "product/classificationtypetwolist?typeOneId=";
  CLASSIFICATIONSUBTYPETHREELIST_URL = "product/classificationtypethreelist?typeTwoId=";
  CLASSIFICATIONSUBTYPEFOURLIST_URL = "product/classificationtypefourlist?typeThreeId=";
  CLASSIFICATIONCATEGORYLEVEL_URL = "product/classificationcategorylevel?categoryId=";

  INVENTORYISBNTYPEAHEADSEARCH_URL = "product/inventoryisbntypeaheadsearch?searchText=#searchText";
  PRODUCTPROJECTISBNTYPEAHEADSEARCH_URL = "product/projectisbntypeaheadsearch?searchText=#searchText";
  FAMILYNAMETYPEAHEADSEARCH_URL = "product/familynametypeaheadsearch?searchText=";
  CREATEFAMILYDETAIL_URL="product/createfamilydetail"
  UPDATEFAMILYDETAIL_URL="product/updatefamilydetail"
  //PRODUCTFAMILYISBNTYPEAHEADSEARCH_URL = "product/familyisbntypeaheadsearch?searchText=#searchText";
  //PRODUCTRELATEDISBNTYPEAHEADSEARCH_URL = "product/relatedisbntypeaheadsearch?searchText=";
  CREATEPROJECT_URL =  "product/createproject";
  UPDATEPRODUCTPRICE_URL = "product/updateproductprice";
  PRODUCTROYALTYLIST_URL = "product/productroyaltylist?titleId=";
  CREATEPRODUCTROYALTY_URL = "product/createproductroyalty"
  UPDATEPRODUCTROYALTY_URL = "product/updateproductroyalty";
  DELETEPRODUCTROYALTY_URL = "product/deleteproductroyalty?id=";
  UPDATEPRODUCTFAMILYNAME_URL = "product/updateproductfamilyname";
  //------product price
  PRODUCTPRICELIST_URL = "product/productpricelist?titleId=#titleId";
  CREATEPRODUCTPRICEDETAIL_URL = "product/createproductpricedetail";
  UPDATEPRODUCTPRICEDETAIL_URL = "product/updateproductpricedetail";
  DELETEPRODUCTPRICEDETAIL_URL = "product/deleteproductpricedetail?id=#id";
  //------product relationship
  UPDATEPRODUCTRELATIONSHIP_URL = "product/updateproductrelationship";
  PRODUCTRELATIONLIST_URL = "product/relationshiplist?titleId=";
  DELETEPRODUCTRELATION_URL = "product/deleteproductrelation?productRelationId=";

  PRODUCTRELATIONSHIPLIST_URL = "product/relationshiplist?titleId=#titleId";
  GETPRODUCTRELATIONSHIPDETAIL_URL = "product/relationshipdetail?id=#id";
  CREATEPRODUCTRELATIONSHIPDETAIL_URL = "product/createproductrelationshipdetail";
  UPDATEPRODUCTRELATIONSHIPDETAIL_URL = "product/updateproductrelationshipdetail";
  DELETEPRODUCTRELATIONSHIPDETAIL_URL = "product/deleteproductrelationshipdetail?id=#id";
//---end product relationship tab
  PRODUCTDISTRIBDETAIL_URL = "product/distribdetail?titleId=";
  UPDATEPRODUCTDISTRIB_URL = "product/updatedistribdetail";
  PRODUCTDISTRIBRIGHTS_URL = "product/distribrights?titleId=";
  UPDATEPRODUCTDISTRIBRIGHTS_URL = "product/updatedistribrights?titleId=";
  //---------product metadatatags
  PRODUCTMETADATATAGS_URL = "product/metadatatags?titleId=";
  PRODUCTMETADATATAG_URL = "product/metadatatag?id=#id";
  CREATEPRODUCTMETADATATAG_URL = "product/createmetadatatag";
  UPDATEPRODUCTMETADATATAG_URL = "product/updatemetadatatag";
  UPDATEPRODUCTMETADATATAGS_URL = "product/updatemetadatatags";
  DELETEPRODUCTMETADATATAG_URL = "product/deleteproductmetadata?id=#id";
  //------end product metadatatags
  PRODUCTSETS_URL = "product/setlist?titleId=#titleId";
  UPDATEPRODUCTSETS_URL = "product/updatesets";
  UPDATEPRODUCTSET_URL = "product/updateset";
  CREATEPRODUCTSET_URL = "product/createset";
  DELETEPRODUCTSET_URL = "product/deleteproductset?id=#id";
  DELETEPRODUCTSETS_URL = "product/deleteproductsets?titleId=";

  PRODUCTRESPONSIBILITY_URL = "product/productresponsibility?titleId=";
  ADDPRODUCTRESPONSIBILITY_URL = "product/createproductresponsibility";
  UPDATEPRODUCTRESPONSIBILITY_URL = "product/updateproductresponsibility";
  DELETEPRODUCTRESPONSIBILITY_URL = "product/productresponsibility?titleId=";

  // Admin Module Url's
  //user
  USERLIST_URL = "user/userlist?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  USERBYID_URL = "user/userDetail?userId=";
  ADDUSER_URL =  "user/adduser";
  UPDATEUSER_URL =  "user/updateuser";
  DELETEUSER_URL =  "user/deleteusers";
  USERTYPEAHEADSEARCH_URL = "user/usertypeaheadsearch?searchText=#searchText";
  //Role
  ROLELIST_URL = "user/rolelist";
  ADDROLE_URL = "user/addrole";
  UPDATEROLE_URL = "user/updaterole";
  DELETEROLE_URL = "user/deleteroles";
  //Permission
  PERMISSIONLIST_URL = "user/permissionlist"
  ADDPERMISSION_URL = "user/addpermission";
  //User Role
  USERROLELIST_URL = "user/userrolelist"
  ADDUSERROLE_URL = "user/adduserrole"
   //Role Permission
   ROLEPERMISSIONBYROLE_URL = "user/rolepermissionbyroleId?roleId="
   ADDROLEPERMISSION_URL = "user/addrolepermission"
   DELETEROLEPERMISSION_URL = "user/deleterolesPermission"

   //batch upload/update start
   PUBDATEBATCHUPLOAD_URL = "admin/pubdatebatchupload";
   PRODUCTPRICEBATCHUPLOAD_URL = "admin/productpricebatchupload";
   //batch uploadEnd

  // Master data Url's
  MASTERDATALIST_URL = "product/masterdatalist";
  COMPANYGROUPLIST_URL = "common/companygrouplist";
  LANGUAGELIST_URL = "common/languagelist";
  TRIMSIZELIST_URL = "common/trimsizelist";
  SECTIONCOLORLIST_URL = "common/sectioncolorlist";
  VERSIONTYPELIST_URL = "common/versiontypelist";
  MEDIUMLIST_URL = "common/mediumlist";
  BINDINGLIST_URL = "common/bindinglist";
  // DISCIPLINELIST_URL = "common/disciplinelist";
  PUBLISHSTATUSLIST_URL = "common/publishstatuslist";
  RESPONSIBILITYTYPELIST_URL = "common/responsibilitytypelist";
  AUTHORTYPELIST_URL = "common/authortypelist";
  BOOKAUTHORTYPELIST_URL = "common/bookauthortypelist";
  AUTHORTITLELIST_URL = "common/authortitlelist";
  ADDRESSTYPELIST_URL = "common/addresstypelist";
  CONTACTTYPELIST_URL = "common/contacttypelist";
  COUNTRYLIST_URL = "common/countrylist";
  AFFILIATIONLIST_URL = "common/affiliationlist";
  FILEUPLOAD_URL="common/fileupload";
  CLASSIFICATIONCATEGORYLIST_URL = "common/classificationcategorylist";
  PAYMENTMETHODLIST_URL = "common/paymentmethodlist";
  PRICETYPELIST_URL = "common/pricetypelist";
  CURRENCYLIST_URL = "common/currencylist";
  RELATIONTYPELIST_URL = "common/relationtypelist";
  SEASONLIST_URL = "common/seasonlist";
  DISCGROUPLIST_URL = "common/discgrouplist";
  PUBLISHERLIST_URL = "common/publisherlist";
  DISCIPLINELIST_URL = "common/disciplinelist?companyGroupId=";
  SUBDISCIPLINELIST_URL = "common/subdisciplinelist?disciplineId=";
  ROYALTYDISCIPLINELIST_URL = "common/royaltydisciplinelist";
  ROYALTYSUBDISCIPLINELIST_URL = "common/royaltysubdisciplinelist?disciplineId=";
  DRIGHTSAREALIST_URL = "common/drightsarealist";
  STATELIST_URL = "common/statelist?countryId=";
  WITHHOLDINGTAXTYPELIST_URL = "common/withholdingtaxtypeList";
  CREATECLASSIFICATIONCATEGORY_URL = "common/createclassificationcategory";
  CREATECLASSIFICATIONTYPEONE_URL = "common/createclassificationtypeone";
  CREATECLASSIFICATIONTYPETWO_URL = "common/createclassificationtypetwo";
  CREATECLASSIFICATIONTYPETHREE_URL = "common/createclassificationtypethree";
  CREATECLASSIFICATIONTYPEFOUR_URL = "common/createclassificationtypefour";
  UPDATECLASSIFICATIONCATEGORY_URL = "common/updateclassificationcategory";
  UPDATECLASSIFICATIONTYPEONE_URL = "common/updateclassificationtypeone";
  UPDATECLASSIFICATIONTYPETWO_URL = "common/updateclassificationtypetwo";
  UPDATECLASSIFICATIONTYPETHREE_URL = "common/updateclassificationtypethree";
  UPDATECLASSIFICATIONTYPEFOUR_URL = "common/updateclassificationtypefour";
  FAMILYDETAILLIST_URL = "common/familydetaillist";
  // Production Module Url's
  PRODUCTIONPRICEELEMENTLIST_URL = "common/productionpriceelementlist";
  PRODUCTIONCLASSIFICATIONLIST_URL = "common/productionclassificationlist";
  PRODUCTIONFORMATLIST_URL = "common/productionformatlist";
  PRODUCTIONMETHODLIST_URL = "common/productionmethodlist";
  PRODUCTIONBINDINGMETHODLIST_URL = "common/productionbindingmethodlist";
  PRODUCTIONSPINESTYLELIST_URL = "common/productionspinestylelist";
  PRODUCTIONILLUSTRATIONTYPELIST_URL = "common/productionillustrationtypelist";
  PRODUCTIONILLUSTRATIONCATEGORYLIST_URL = "common/productionillustrationcategorylist";

  PRODUCTIONBINDINGMATERIALLIST_URL = "common/productionbindingmateriallist";
  PRODUCTIONSURFACELIST_URL = "common/productionsurfacelist";
  PRODUCTIONCOLORATIONLIST_URL = "common/productioncolorationlist";
  PRODUCTIONSPECIFICATIONLIST_URL = "common/productionspecificationlist";
  PRODUCTIONFINISHINGLIST_URL = "common/productionfinishinglist";
  PRODUCTIONBINDINGPAPERQUALITYLIST_URL = "common/productionbindingpaperqualitylist";
  PRODUCTIONPERSHEETLIST_URL = "common/productionpersheetlist";
  PRODUCTIONEXTERIORCOLORLIST_URL = "common/productionexteriorcolorlist";
  PRODUCTIONINTERIORCOLORLIST_URL = "common/productioninteriorcolorlist";
  PRODUCTIONBINDINGGRAMMAGELIST_URL = "common/productionbindinggrammagelist";
  PRODUCTIONBOARDTHICKNESSLIST_URL = "common/productionboardthicknesslist";
  PRODUCTIONFRONTPAPERCOLORLIST_URL = "common/productionfrontpapercolorlist";
  PRODUCTIONBACKPAPERCOLORLIST_URL = "common/productionbackpapercolorlist";
  PRODUCTIONENDPAPERGRAMMAGELIST_URL = "common/productionendpapergrammagelist";
  PRODUCTIONPARTSNAMELIST_URL = "common/productionpartsnamelist";
  PRODUCTIONPARTSMATERIALLIST_URL = "common/productionpartsmateriallist";
  PRODUCTIONPARTSPAPERQUALITYLIST_URL = "common/productionpartspaperqualitylist";
  PRODUCTIONPARTSGRAMMAGELIST_URL = "common/productionpartsgrammagelist";
  PRODUCTIONPARTSPAGESINCHLIST_URL = "common/productionpartspagesinchlist";
  PRODUCTIONPARTSCOLORLIST_URL = "common/productionpartscolorlist";
  PRODUCTIONPAPERSIZELIST_URL = "common/productionpapersizelist";
  PRODUCTIONBLEEDLIST_URL = "common/productionbleedlist";
  PRODUCTIONSCHEDULETEMPLATELIST_URL = "common/productionscheduletemplatelist";
  PRODUCTIONSCHEDULETASKLIST_URL = "common/productionscheduletasklist?templateId=";
  PRODUCTIONNOTESTYPELIST_URL = "common/productionnotestypelist";
  SUPPLIERLIST_URL = "common/supplierlist";
  PRODUCTIONPOTEMPLATELIST_URL = "common/productionpotemplatelist";
  PRODUCTIONPODELIVERYTYPELIST_URL = "common/productionpodeliverytypelist";
  PRODUCTIONENDPAPERMATERIALLIST_URL = "common/productionendpapermateriallist";
  PRODUCTIONINSERTMATERIALLIST_URL = "common/productioninsertmateriallist";
  PRODUCTIONINSERTCOLORATIONLIST_URL = "common/productioninsertcolorationlist";
  PRODUCTIONINSERTSPECLIST_URL = "common/productioninsertspeclist";
  PRODUCTIONINSERTRECTOCOLORLIST_URL = "common/productioninsertrectocolorlist";
  PRODUCTIONINSERTFINISHINGLIST_URL = "common/productioninsertfinishinglist";
  PRODUCTIONINSERTPAGESPERSHEETLIST_URL = "common/productioninsertpagespersheetlist";
  PRODUCTIONINSERTVERSOCOLORLIST_URL = "common/productioninsertversocolorlist";

  TABLECOLUMNHEADERSLIST_URL = "common/tablecolumnheaders?view=#view";
  TABLECOLUMNHEADERSLISTFORUSER_URL = "common/tablecolumnheadersforuser?userId=";
  UPDATETABLECOLUMNHEADERSLISTFORUSER_URL = "common/updatetablecolumnheadersforuser?userId=";

  PRODUCTIONPRODUCTLIST_URL = "production/productlist?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  PRODUCTIONPRODUCTDETAIL_URL = "production/productdetail?isbn=#isbn";
  PRODUCTIONSPECIFICATION_URL = "production/productspecification?titleId=";
  UPDATEPRODUCTIONSPECIFICATION_URL = "production/updateproductspecification";
  PRODUCTIONILLUSTRATIONLIST_URL = "production/illustrationlist?titleId=";
  UPDATEPRODUCTIONILLUSTRATION_URL = "production/updateproductillustration";
  PRODUCTIONCOVERBINDING_URL = "production/productcoverbinding?titleId=";
  UPDATEPRODUCTIONCOVERBINDING_URL = "production/updateproductcoverbinding";
  PRODUCTIONCOVERENDPAPER_URL = "production/productcoverendpaper?titleId=";
  UPDATEPRODUCTIONCOVERENDPAPER_URL = "production/updateproductcoverendpaper";
  PRODUCTIONSCHEDULELIST_URL = "production/productschedulelist?titleId=";
  UPDATEPRODUCTIONSCHEDULE_URL = "production/updateproductschedule";
  DELETEPRODUCTSCHEDULE_URL = "production/deleteproductschedule?titleId=";
  PRODUCTIONNOTES_URL = "production/productnotes?titleId=";
  UPDATEPRODUCTIONNOTE_URL = "production/updateproductnote";
  PRODUCTIONPARTS_URL = "production/productparts?titleId=";
  UPDATEPRODUCTIONPARTS_URL = "production/updateproductparts";
  PRODUCTIONSELECTEDSUPPLIERLIST_URL = "production/selectedsupplierlist?titleId=";
  PRODUCTIONCOSTLIST_URL = "production/productcostlist?titleId=";
  UPDATEPRODUCTIONCOST_URL = "production/updateproductcost";
  REMOVEPRODUCTIONCOST_URL = "production/removeproductcost";
  PRODUCTIONSUPPLIERCOSTLIST_URL = "production/suppliercostlist?titleId=";
  PRODUCTIONPURCHASE_URL = "production/productpurchase?titleId=";
  UPDATEPRODUCTIONPURCHASE_URL = "production/updateproductpurchase";
  GENERATEPURCHASEORDER_URL = "production/generatepurchaseorder?printId=";
  PRODUCTIONPRINTINFOLIST_URL = "production/productprintinfolist?titleId=";
  PRODUCTIONPURCHASEORDERLIST_URL = "production/productpurchaseorderlist?titleId=";
  PRODUCTIONPURCHASEORDERS_URL = "production/productpurchaseorders?poNumber=";
  //DOWNLOADPRODUCTPURCHASEORDER_URL = "production/downloadpurchaseorder?isbn=#isbn&printNumber=#printNumber&supplier=#supplier&poTemplate=#poTemplate";
  DOWNLOADPRODUCTPURCHASEORDER_URL = "production/downloadpurchaseorder?isbn=#isbn&poDate=#poDate&filename=#filename";
  ADDPURCHASEORDERDELIVERYADDRESS_URL = "production/addpodeliveryaddress";
  PRODUCTPURCHASEORDERDELIVERYADDRESSLIST_URL = "production/podeliveryaddresslist?purchaseDetailId=";
  DELETEPRODUCTPURCHASEORDERDELIVERYADDRESS_URL = "production/deletepodeliveryaddress?poDeliveryDetailId=";
  SUPPLIERTYPEAHEADSEARCH_URL = "production/suppliertypeaheadsearch?searchText=#searchText";
  ADDPRODUCTIONNOTE_URL = "production/addproductnote";
  DELETEPRODUCTIONNOTE_URL = "production/deleteproductnote?noteId=";
  GENERATEPURCHASEORDERNUMBER_URL = "production/generateponumber";
  GETMAXPURCHASEORDERNUMBER_URL = "production/maxponumber?isSet=";
  ADDPRODUCTIONILLUSTRATION_URL = "production/addproductillustration";
  DELETEPRODUCTIONILLUSTRATION_URL = "production/deleteproductillustration?illustrationId=";
  PRODUCTIONPRINTPURCHASEORDERS_URL = "production/printpurchaseorders?titleId=";
  ADDPRODUCTIONPRINTINFO_URL = "production/addproductprintinfo";
  UPDATEPRODUCTIONPRINTINFO_URL = "production/updateproductprintinfo";
  DELETEPRODUCTIONPRINTINFO_URL = "production/deleteproductprintinfo?titleId=";
  GENERATEPURCHASEORDERBYSUPPLIER_URL = "production/generatepurchaseorderbysupplier?printId=";
  PRODUCTIONPARTINSERT_URL = "production/productpartinsert?titleId=";
  UPDATEPRODUCTIONPARTINSERT_URL = "production/updateproductpartinsert";
  PRODUCTIONPARTINSERTLIST_URL = "production/partinsertlist?titleId=";
  DELETEPRODUCTIONPARTINSERT_URL = "production/deleteproductpartinsert?partInsertId=";
  SUPPLIERPRICESCALES_URL = "production/supplierpricescales?supplierId=";
  DOWNLOADPRICEELEMENTTEMPLATE_URL = "production/downloadpriceelementtemplate?peTemplateCode=#peTemplateCode&filename=#filename";
  UPLOADPRICEELEMENTSCALE_URL = "production/uploadpriceelementscale?supplierId=";
  PRODUCTIONDATA_URL = "production/productdata?titleId=";
  SUPPLIERPRICESCALE_URL = "production/supplierpricescale?supplierId=";

  // TypeAhead Search
  AFFILIATIONTYPEAHEADSEARCH_URL = "common/search/affiliation?searchText=#searchText";
  // Author Module Url's
  AUTHORLIST_URL = "author/authorlist?searchText=#searchText&authorTypeId=#authorTypeId&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  AUTHORLISTWITHTYPE_URL = "author/authorlistwithtype?searchText=#searchText&authorTypeId=#authorTypeId&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  AUTHORDETAIL_URL = "author/authordetail?authorId=";
  CREATEAUTHOR_URL =  "author/createauthor";
  UPDATEAUTHOR_URL = "author/updateauthor";
  UPDATEAUTHORLIST_URL = "author/updateauthors";
  UPDATEAUTHORNOTES_URL = "author/updateauthornotes";
  AUTHORISBNTYPEAHEADSEARCH_URL = "author/authortypeaheadsearch?searchText=#searchText";
  AUTHORADDRESSLIST_URL = "author/authoraddresslist?authorId=";
  AUTHORADDRESSDETAIL_URL = "author/authoraddressdetail?authorId=";
  UPDATEAUTHORADDRESS_URL = "author/updateauthoraddress";
  DELETEAUTHORADDRESS_URL = "author/deleteauthoraddress?authorId=";
  CREATEAUTHORADDRESS_URL = "author/createauthoraddress";
  AUTHORROYALTYDETAIL_URL = "author/authorroyaltydetail?authorId=";
  UPDATEAUTHORROYALTYDETAIL_URL = "author/updateauthorroyaltydetail";
  SENDAUTHORDATATOSAP_URL =  "author/sendauthordatatosap";
  //---author affiliation
  AUTHORAFFILIATIONLIST_URL = "author/affiliation/list?authorId=#authorId";
  AUTHORAFFILIATIONDETAIL_URL = "author/affiliation/detail?id=#id";
  CREATEAUTHORAFFILIATION_URL =  "author/affiliation/create";
  UPDATEAUTHORAFFILIATION_URL = "author/affiliation/update";
  DELETEAUTHORAFFILIATION_URL =  "author/affiliation/delete";
  RESTOREAUTHORAFFILIATION_URL = "author/affiliation/restore";
  PRODUCTIONPRICEELEMENTSLABS_URL = "author/priceelementslabs?priceElementId=#priceElementId";

  //Metadata Modules Url's
  METADATALIST_URL = "metadata/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  METADATADETAILS_URL="metadata/detail?id=#id";
  METADATACREATE_URL =  "metadata/create";
  METADATAUPDATE_URL = "metadata/update";
  METADATADELETE_URL="metadata/delete?id=#id";
  METADATARESTORE_URL="metadata/restore?id=#id";
  METADATATYPEAHEADSEARCH_URL = "metadata/metadatatypeaheadsearch?searchText=#searchText";

  //metadata Group
  METADATAGROUPLIST_URL = "metadata/group/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  METADATAGROUPCREATE_URL =  "metadata/group/create";
  METADATAGROUPUPDATE_URL =  "metadata/group/update";
  METADATAGROUPDELETE_URL="metadata/group/delete?id=#id";
  METADATAGROUPRESTORE_URL="metadata/group/restore?id=#id";
  METADATAGROUPDETAIL_URL =  "metadata/group/detail?id=#id";
  METADATAGROUPDETAILBYCODE_URL =  "metadata/group/detail?id=0&groupcode=#groupcode";
  METADATAGROUPTYPEAHEADSEARCH_URL = "metadata/group/metadatagrouptypeaheadsearch?searchText=#searchText";
  // modulesetup metadata
  METADATAMODULESETUPLIST_URL = "metadata/modulesetup/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  METADATAMODULESETUPCREATE_URL =  "metadata/modulesetup/create";
  METADATAMODULESETUPUPDATE_URL =  "metadata/modulesetup/update";
  METADATAMODULESETUPDETAIL_URL =  "metadata/modulesetup/detail?id=#id";
  METADATAMODULESETUPDELISTBYSCREENNAME_URL =  "metadata/modulesetup/list/byscreenname?screenname=#screenname";
  METADATAMODULESETUPDETETE_URL="metadata/modulesetup/delete?id=#id";
  METADATAMODULESETUPRESTORE_URL="metadata/modulesetup/restore?id=#id";
  //Table Metadata Modules Url's
  METADATATABLELIST_URL = "metadata/table/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  METADATATABLEDETAILS_URL="metadata/table/detail?id=#id";
  METADATATABLECREATE_URL =  "metadata/table/create";
  METADATATABLEUPDATE_URL = "metadata/table/update";
  METADATATABLEDELETE_URL="metadata/table/delete?id=#id";
  METADATATABLERESTORE_URL="metadata/table/restore?id=#id";
  METADATATABLELOCK_URL = "metadata/table/lock?id=#id";
  METADATATABLEUNLOCK_URL = "metadata/table/unlock?id=#id";
  METADATATABLETYPEAHEADSEARCH_URL = "metadata/table/metadatatypeaheadsearch?searchText=#searchText";
  METADATATABLEVALIDATE_URL = "metadata/table/validate";
  METADATATABLEEXECUTESCRIPT_URL = "metadata/table/executescript";
  //Table column Metadata Modules Url's
  METADATATABLECOLUMNLIST_URL = "metadata/tablecolumn/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";

  METADATATABLECOLUMNS_URL = "metadata/tablecolumn/list?tableid=#tableid";
  METADATATABLECOLUMNDETAILS_URL="metadata/tablecolumn/detail?id=#id";
  METADATATABLECOLUMNCREATE_URL =  "metadata/tablecolumn/create";
  METADATATABLECOLUMNUPDATE_URL = "metadata/tablecolumn/update";
  METADATATABLECOLUMNDELETE_URL="metadata/tablecolumn/delete?id=#id";
  METADATATABLECOLUMNRESTORE_URL="metadata/tablecolumn/restore?id=#id";
  METADATATABLECOLUMNTYPEAHEADSEARCH_URL = "metadata/tablecolumn/columntypeaheadsearch?searchText=#searchText";
  METADATATABLECOLUMNEXECUTESCRIPT_URL = "metadata/tablecolumn/executescript";
  //-------------table relation

  METADATATABLERELATIONLIST_URL = "metadata/tablerelation/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  METADATATABLERELATIONDETAILS_URL="metadata/tablerelation/detail?id=#id";
  METADATATABLERELATIONCREATE_URL =  "metadata/tablerelation/create";
  METADATATABLERELATIONUPDATE_URL = "metadata/tablerelation/update";
  METADATATABLERELATIONDELETE_URL="metadata/tablerelation/delete?id=#id";
  METADATATABLERELATIONRESTORE_URL="metadata/tablerelation/restore?id=#id";
  METADATATABLERELATIONLOCK_URL = "metadata/tablerelation/lock?id=#id";
  METADATATABLERELATIONUNLOCK_URL = "metadata/tablerelation/unlock?id=#id";
  METADATATABLERELATIONTYPEAHEADSEARCH_URL = "metadata/tablerelation/metadatatypeaheadsearch?searchText=#searchText";
  METADATATABLERELATIONSCRIPTVALIDATE_URL = "metadata/tablerelation/script/validate";
  METADATATABLERELATIONSCRIPTCREATE_URL = "metadata/tablerelation/script/create";
  METADATATABLERELATIONSCRIPTALTER_URL = "metadata/tablerelation/script/alter";

  //-------------group metadata form submit data save dynamically to given target db table
  GROUPDBMETADATACREATE_URL =  "metadata/groupdb/create";
  GROUPDBMETADATAUPDATE_URL =  "metadata/groupdb/update";
  GROUPDBMETADATADETAIL_URL =  "metadata/groupdb/detail";
  GROUPDBMETADATALIST_URL =  "metadata/groupdb/list";
  GROUPDBMETADATADETAILBY_GC_PID_ID_URL =  "metadata/groupdb/#gc/#pid/#id/detail";
 //-------------help metadata for screen tab section
  HELPMETADATACREATE_URL =  "metadata/helpguide/create";
  HELPMETADATAUPDATE_URL =  "metadata/helpguide/update";
  HELPMETADATADETAIL_URL =  "metadata/helpguide/detail?id=#id"; 
  HELPMETADATADELETE_URL =  "metadata/helpguide/delete?id=#id"; 
  HELPMETADATARESTORE_URL =  "metadata/helpguide/restore?id=#id";
  HELPMETADATALIST_URL =  "metadata/helpguide/list?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  HELPMETADATADETAILSBY_SC_VW_TB_URL =  "metadata/helpguide/#sc/#vw/#tb/details";

  //-------------Title Tab data
  TITLETABDATACREATE_URL =  "titletabdata/create";
  TITLETABDATAUPDATE_URL =  "titletabdata/update";
  TITLETABDATADELETE_URL =  "titletabdata/delete";
  TITLETABDATADRESTORE_URL =  "titletabdata/restore";
  TITLETABDATADETAIL_URL =  "titletabdata/detail?tableName=#tableName&id=#id";
  TITLETABDATALIST_URL =  "titletabdata/list?tableName=#tableName&titleId=#titleId&searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";

  // DataFeed Module Url's
  CHANNELLIST_URL = "datafeed/channellist";//?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  CHANNESETTINGLLIST_URL = "datafeed/channelsettinglist?searchText=#searchText&page=#page&pageSize=#pageSize&sortField=#sortField&sortOrder=#sortOrder";
  ADDSETTINGLLIST_URL = "datafeed/addchannelsetting";
  UPDATESETTINGLLIST_URL = "datafeed/updatechannelsetting";
  DELETESETTINGLLIST_URL = "datafeed/deletechannelsetting";
  INTERNALFIELDLIST_URL = "datafeed/internalfieldlist";
  CHANNELFIELDMAPPINGLIST_URL = "datafeed/channelfieldmappinglist";
  ADDCHANNELFIELDMAPPING_URL = 'datafeed/addchannelfieldmapping';
  DATAFEEDTEMPLATEBYID_URL = 'datafeed/datafeedtemplatebyid?channelSettingId=';
  ADDDATAFEEDTEMPLATE_URL = 'datafeed/adddatafeedtemplate';
  UPDATEDATAFEEDTEMPLATE_URL = 'datafeed/updatedatafeedtemplate';
  GETDATAFEED_URL = 'datafeed/getdatafeed?channelId=';
  GETCSVFEED_URL = 'datafeed/getcsvfeed?channelId=';

  //media upload setting data of API
 static fnMediaUploadServerSetting  ( ) {
    return  {
                  resourseUrlKeyName: "ResourcesPathUrl" ,
                  authorImageUrlKeyName: "AuthorImagePathUrl"  ,
                  targetauthorImageFolderKeyName: "authorsimages"
              };
    }
}
