import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public appSettings: any = '';
  baseUrl: string = '';
  webConfig_Url: string = 'assets/web.config.json';

  constructor(private http: HttpClient) {
    this.baseUrl = environment.base_URL;
  }

  GET_URL(api_URL: string) {
    if(this.baseUrl)
      return this.baseUrl + api_URL;
    else
      return api_URL;
  }

   public getAppSettings() : Observable<any> {
    return this.http.get(this.webConfig_Url);
  }

  getHttpHeaders() {
    var accessToken = '';
    if (localStorage.getItem('tmsUserAccessToken')) {
      accessToken = 'Bearer ' + localStorage.getItem('tmsUserAccessToken');
    }

    const userJson = localStorage.getItem('tms.user_details');
    const user = userJson !== null ? JSON.parse(userJson) : undefined;
    const headers = new HttpHeaders({
      // 'X-Auth-Token': user !== undefined ? user.authtoken : '',
      // 'X-permissions': user !== undefined ? user.permissions : '',
      'X-Username': user !== undefined ? user.username : '',
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: accessToken,
    });

    return headers;
  }

  getUploadHeaders() {
    var accessToken = '';
    if (localStorage.getItem('tmsUserAccessToken')) {
      accessToken = 'Bearer ' + localStorage.getItem('tmsUserAccessToken');
    } 
    const headers = new HttpHeaders({
      Authorization: accessToken,
    });
    headers.append ('Content-Type', 'application/x-www-form-urlencoded');
    return headers;
  }

  getHeaders() {
    const headers = this.getHttpHeaders();

    const options = {
      headers: headers,
    };
    return options;
  }

  GET(path: string): Observable<any> {
    return this.http.get(this.baseUrl + path, this.getHeaders());
  }

  POST(path: string, params: any): Observable<any> {
    return this.http.post(this.baseUrl + path, params, {headers: this.getHttpHeaders(), responseType: 'text'});
  }

  PUT(path: string, putData: any): Observable<any> {
    return this.http.put(this.baseUrl + path, putData, {headers: this.getHttpHeaders(), responseType: 'text'});
  }

  DELETE(path: string): Observable<any> {
    return this.http.delete(this.baseUrl + path, {headers: this.getHttpHeaders(), responseType: 'text'});
  }

  // DELETEMULTY(path: string, delData:any[]): Observable<any> {
  //   return this.http.delete(this.baseUrl + path, {headers: this.getHttpHeaders(), responseType: 'text'},delData);
  // }
}
