import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.css']
})
export class JsonViewerComponent {
  @Input() jsonData: any; // Input property to receive JSON data
  @Output() onDataChange: EventEmitter<any> = new EventEmitter();
  constructor() { }


  // Method to handle changes in JSON data
  onJsonChange_txtarea(updatedData: any) {updatedData.target.innerText
    try {
      const myObjStr = JSON.stringify(updatedData.target.value);
      this.jsonData = JSON.parse(myObjStr);
      this.jsonData = JSON.stringify(this.jsonData , undefined, 4)
      // You can emit an event or perform any necessary action with the updated JSON data here
    } catch (error:any) {
      console.error('Invalid JSON format:',error.message);
      // Handle the error or invalid JSON format
    }
  }

  onJsonChange(updatedData: any) {
    try {  
      if(updatedData.target.innerText !="" && updatedData.target.innerText.length>10) 
        this.jsonData = JSON.parse(updatedData.target.innerText);
        this.onDataChange.emit(this.jsonData);
    } catch (error:any) {
      console.error('Invalid JSON format:',error.message);
    }
  }
}
