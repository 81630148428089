<p-toast></p-toast>
<div class="container-fluid">
    <div class="row d-flex flex-wrap">
        <div class="col p-2 bg-light border-bottom">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <div class="col-12 col-lg-4 col-md-4 col-sm-4 mb-2 mb-md-0 d-flex">
                    <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none">
                        <img src="assets/images/header_logo.png" alt="" data-atf="1" data-frt="0" height="45px">
                    </a>    
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row d-flex justify-content-center align-items-center h-auto" style="min-height: 83.5vh;">
        <div class="col-md-9 col-lg-6 col-xl-5">
            <img src="assets/images/login_page_image.png" class="img-fluid" alt="">
            <svg version="1.0"  width="500" height="264" viewBox="0 0 900.000000 264.000000" preserveAspectRatio="xMidYMid meet" class="d-none"> 
                <g transform="translate(0.000000,264.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path fill="smoke" d="M1972 1323 l3 -1318 25 0 25 0 3 1318 2 1317 -30 0 -30 0 2 -1317z" class="svg-elem-1"></path>
                <path fill="Maroon" d="M680 2248 c-14 -6 -38 -16 -55 -23 -16 -7 -50 -21 -75 -30 -25 -9 -58 -23 -73 -31 -16 -8 -35 -14 -42 -14 -14 0 -66 -20 -118 -46 -16 -8 -36 -14 -46 -14 -9 0 -26 -7 -37 -15 -10 -8 -28 -15 -40 -15 -11 0 -29 -7 -40 -15 -10 -8 -26 -15 -34 -15 -9 0 -33 -9 -55 -20 -22 -11 -45 -20 -52 -20 -9 0 -13 -15 -13 -45 0 -40 2 -45 23 -45 13 0 32 -7 43 -15 18 -14 17 -16 -23 -53 l-43 -40 0 -75 0 -76 103 103 c96 97 223 196 251 196 6 0 17 5 23 11 11 11 59 24 130 36 46 7 102 -14 138 -54 27 -30 33 -46 44 -126 17 -128 20 -137 36 -144 30 -11 63 8 141 83 79 77 186 164 200 164 4 0 16 8 26 17 30 27 180 23 236 -7 73 -38 94 -76 105 -191 8 -82 12 -97 23 -88 11 9 14 47 14 163 0 169 -4 182 -60 196 -35 9 -62 18 -170 61 -30 11 -66 23 -80 26 -14 3 -34 12 -45 19 -11 8 -29 14 -40 14 -11 0 -29 7 -39 15 -11 8 -28 15 -37 15 -10 0 -30 6 -46 14 -15 8 -48 22 -73 31 -25 10 -56 22 -70 28 -39 17 -94 37 -100 36 -3 0 -16 -5 -30 -11z m171 -149 c57 -22 114 -44 125 -49 22 -9 36 -41 25 -57 -3 -4 -41 -36 -85 -71 -101 -79 -123 -81 -155 -13 -23 49 -54 85 -104 122 -49 37 -51 53 -9 83 50 35 82 33 203 -15z" class="svg-elem-2"></path>
                <path fill="smoke" d="M2515 1952 c-9 -10 -5 -40 9 -69 13 -26 22 -49 56 -143 6 -19 16 -51 20 -70 4 -19 14 -51 20 -70 32 -88 38 -109 55 -165 9 -33 23 -75 31 -93 8 -18 14 -40 14 -48 0 -9 6 -30 14 -47 8 -18 16 -43 19 -57 6 -28 22 -78 43 -128 8 -18 14 -45 14 -60 0 -52 7 -55 113 -53 62 1 67 3 67 24 0 24 13 68 70 227 6 19 16 51 20 70 9 39 25 87 46 138 8 18 14 41 14 52 0 11 6 34 14 52 8 18 22 60 31 93 10 33 23 76 30 95 7 19 18 51 24 70 7 19 16 50 21 68 4 18 13 39 20 47 6 7 10 26 8 42 -3 27 -4 28 -58 24 -66 -4 -78 -12 -85 -60 -3 -20 -10 -49 -16 -66 -6 -16 -15 -46 -19 -65 -4 -19 -13 -50 -19 -67 -6 -18 -16 -48 -22 -65 -6 -18 -15 -49 -19 -68 -4 -19 -13 -48 -18 -65 -6 -16 -18 -57 -27 -90 -9 -33 -21 -73 -27 -90 -6 -16 -14 -48 -18 -70 -32 -157 -34 -166 -48 -163 -19 4 -38 44 -51 108 -24 115 -29 136 -39 165 -6 17 -15 46 -22 65 -7 19 -16 51 -20 70 -8 32 -28 100 -66 225 -8 28 -19 66 -23 85 -4 19 -13 45 -19 57 -7 12 -12 36 -12 53 0 17 -6 32 -12 35 -22 6 -128 12 -133 7z" class="svg-elem-3"></path> 
                <path fill="smoke" d="M4180 1918 c-7 -18 -16 -49 -20 -68 -8 -36 -23 -81 -45 -135 -17 -40 -35 -93 -45 -130 -11 -39 -29 -91 -46 -133 -8 -18 -14 -43 -14 -55 0 -12 -7 -31 -15 -41 -8 -11 -15 -32 -15 -46 0 -14 -5 -31 -11 -37 -6 -6 -14 -26 -19 -44 -13 -54 -53 -165 -80 -223 -26 -55 -18 -66 45 -66 59 0 81 14 90 58 8 36 35 128 51 175 8 20 14 46 14 57 0 20 6 20 192 18 l193 -3 6 -35 c7 -33 11 -47 39 -130 7 -19 15 -54 20 -78 4 -23 10 -45 13 -49 4 -3 35 -8 70 -10 76 -5 85 4 57 63 -27 58 -67 169 -80 223 -5 18 -13 38 -19 44 -6 6 -11 20 -11 30 0 10 -6 33 -14 50 -8 18 -16 43 -19 57 -6 33 -26 88 -43 123 -8 16 -14 39 -14 51 0 12 -7 31 -15 42 -8 10 -15 30 -15 42 0 13 -6 36 -14 50 -17 35 -36 88 -46 132 -4 19 -14 50 -21 68 -13 32 -14 32 -85 32 l-72 0 -12 -32z m105 -115 c4 -21 11 -51 15 -68 26 -98 42 -154 52 -180 5 -16 13 -45 18 -63 4 -18 13 -39 20 -46 6 -8 12 -33 13 -56 l2 -42 -143 0 c-79 0 -145 2 -148 5 -7 7 17 87 33 112 7 11 13 30 13 43 0 13 5 33 12 45 6 12 15 38 19 57 4 19 13 55 20 80 7 25 19 69 26 98 17 62 39 69 48 15z" class="svg-elem-4"></path> 
                <path fill="smoke" d="M5364 1936 c-11 -30 3 -679 16 -726 22 -80 46 -131 83 -175 42 -49 91 -76 190 -101 66 -17 72 -17 145 1 116 28 170 62 219 138 57 89 62 131 64 517 l1 355 -61 3 -61 3 0 -352 c0 -334 -1 -355 -21 -410 -37 -101 -105 -149 -211 -149 -44 0 -76 6 -102 20 -44 22 -86 58 -86 73 0 6 -6 18 -13 26 -29 34 -39 167 -36 479 l3 307 -62 3 c-49 2 -64 0 -68 -12z" class="svg-elem-5"></path>
                 <path fill="smoke" d="M6930 1948 c0 -2 -2 -227 -3 -501 -2 -453 -1 -497 14 -497 9 0 137 -1 285 -1 l269 -2 3 44 c1 24 -1 49 -5 55 -6 7 -70 9 -213 4 -143 -5 -208 -3 -215 4 -7 7 -9 155 -5 441 3 238 1 436 -4 443 -7 10 -125 19 -126 10z" class="svg-elem-6"></path>
                 <path d="M7970 1893 l0 -54 151 3 151 3 2 -450 1 -450 57 -2 c32 -1 60 3 64 8 4 8 5 565 0 844 l-1 50 155 0 155 0 0 50 0 50 -367 1 -368 1 0 -54z" class="svg-elem-7"></path>
                 <path fill="Maroon" d="M450 1565 c-14 -8 -32 -14 -41 -15 -48 -1 -215 -115 -328 -223 -61 -59 -64 -64 -65 -110 -2 -97 4 -97 101 1 89 90 163 152 181 152 5 0 15 7 22 15 7 8 19 15 28 15 8 0 23 6 31 13 32 26 112 39 171 26 30 -6 61 -16 68 -22 23 -18 54 -90 64 -147 5 -30 13 -73 16 -94 14 -81 64 -74 162 25 72 72 192 169 208 169 5 0 15 6 21 14 13 16 125 28 187 19 53 -7 109 -56 143 -126 24 -50 35 -57 45 -31 13 35 4 77 -30 140 -47 87 -95 131 -152 140 -58 9 -160 -4 -197 -24 -40 -22 -172 -122 -210 -159 -61 -59 -115 -94 -137 -91 -19 2 -23 10 -26 43 -1 22 -10 75 -19 118 -16 73 -21 82 -66 123 -45 40 -54 44 -101 44 -28 -1 -62 -7 -76 -15z" class="svg-elem-8"></path>
                 <path fill="Maroon" d="M440 1025 c-14 -8 -34 -15 -45 -15 -11 -1 -38 -12 -60 -25 -22 -14 -47 -28 -55 -32 -8 -4 -20 -12 -27 -17 -6 -6 -37 -29 -67 -50 -31 -22 -56 -47 -56 -56 0 -9 10 -30 23 -46 34 -45 155 -188 169 -199 7 -6 33 -27 58 -47 25 -20 63 -46 85 -58 22 -12 60 -32 84 -46 24 -13 51 -24 60 -24 9 0 25 -7 35 -15 26 -20 116 -20 142 0 10 8 26 15 35 15 9 0 36 11 60 24 24 14 62 34 84 46 86 47 190 144 265 245 56 75 69 95 76 118 4 9 10 17 15 17 5 0 14 10 19 23 6 12 15 30 22 39 33 45 -91 87 -192 64 -91 -20 -136 -47 -261 -157 -68 -60 -108 -95 -126 -109 -23 -19 -59 -22 -72 -6 -8 9 -18 58 -23 109 -12 129 -14 133 -59 177 -39 38 -45 40 -103 40 -34 -1 -72 -7 -86 -15z m92 -116 c36 -17 58 -55 65 -112 5 -47 11 -57 64 -109 44 -44 65 -58 87 -58 38 0 95 24 141 59 128 97 162 121 209 146 28 15 55 25 59 22 12 -13 -18 -86 -43 -106 -14 -11 -41 -40 -59 -64 -37 -48 -182 -151 -229 -163 -16 -4 -41 -13 -55 -20 -14 -8 -36 -14 -49 -14 -27 0 -122 29 -140 43 -19 15 -73 46 -109 62 -38 17 -183 180 -183 204 0 19 52 72 89 91 58 30 115 37 153 19z" class="svg-elem-9"></path>
                 <path fill="grey" d="M2853 653 c-7 -2 -13 -18 -13 -34 0 -26 3 -29 30 -29 34 0 49 23 33 49 -11 17 -30 23 -50 14z" class="svg-elem-10"></path>
                 <path fill="grey" d="M3220 492 c0 -169 0 -170 25 -186 13 -9 29 -16 35 -16 13 0 13 53 0 66 -5 5 -11 75 -12 154 -3 142 -4 145 -25 148 l-23 3 0 -169z" class="svg-elem-11"></path>
                 <path fill="grey" d="M7101 634 c-46 -20 -61 -38 -68 -76 -7 -45 21 -78 91 -108 60 -25 80 -56 56 -85 -18 -21 -73 -19 -103 5 -24 19 -57 18 -57 -2 0 -24 23 -46 65 -62 43 -17 48 -17 87 -2 51 21 77 52 77 91 0 41 -25 68 -90 98 -71 32 -83 47 -63 75 18 26 38 28 78 7 35 -18 56 -13 56 13 0 22 -15 36 -45 44 -11 3 -27 8 -35 11 -8 3 -30 -1 -49 -9z" class="svg-elem-12"></path>
                 <path fill="grey" d="M2513 614 c-8 -21 15 -36 47 -30 50 9 53 -1 48 -138 -6 -118 -5 -127 14 -137 14 -8 23 -7 35 2 13 11 15 35 12 144 l-4 130 45 0 c38 0 45 3 45 20 0 19 -8 20 -118 23 -101 2 -118 0 -124 -14z" class="svg-elem-13"></path>
                 <path fill="grey" d="M3820 496 c0 -74 -2 -140 -5 -148 -3 -7 1 -22 9 -33 27 -37 41 -4 41 95 0 90 14 121 26 60 4 -18 12 -37 18 -43 6 -6 11 -23 11 -39 0 -17 7 -31 20 -38 23 -13 40 -6 40 17 0 8 6 29 14 46 8 18 16 42 18 55 2 12 8 24 13 25 6 1 11 -35 13 -88 2 -49 7 -94 11 -98 5 -5 17 -4 28 1 18 10 19 22 17 156 -2 80 -5 150 -9 156 -3 5 -17 10 -30 10 -27 0 -45 -25 -45 -62 0 -13 -7 -33 -15 -44 -8 -10 -15 -29 -15 -41 0 -12 -3 -28 -6 -37 -10 -25 -43 4 -50 44 -4 19 -13 44 -20 55 -8 11 -14 31 -14 46 0 27 -15 39 -51 39 -18 0 -19 -9 -19 -134z" class="svg-elem-14"></path>
                 <path fill="grey" d="M3020 599 c0 -11 -8 -34 -17 -50 -16 -26 -16 -29 0 -47 13 -14 17 -37 17 -95 0 -73 1 -76 31 -98 25 -17 37 -20 58 -13 36 13 42 54 8 54 -37 0 -48 22 -45 86 3 53 5 59 28 64 38 9 39 45 1 58 -24 8 -31 17 -31 36 0 21 -5 26 -25 26 -19 0 -25 -5 -25 -21z" class="svg-elem-15"></path>
                 <path fill="grey" d="M6700 597 c0 -13 -9 -32 -20 -42 -24 -22 -26 -41 -3 -53 13 -8 16 -22 14 -67 -3 -72 13 -119 45 -134 35 -15 61 -14 68 5 12 30 6 43 -21 46 -26 3 -29 7 -36 62 -10 71 -5 86 27 86 26 0 36 14 28 43 -2 10 -14 17 -28 17 -17 0 -23 6 -26 28 -2 19 -9 28 -25 30 -19 3 -23 -1 -23 -21z" class="svg-elem-16"></path>
                 <path fill="grey" d="M7857 613 c-4 -3 -7 -16 -7 -28 0 -13 -7 -28 -15 -35 -8 -7 -15 -18 -15 -25 0 -7 7 -18 15 -25 11 -9 15 -33 15 -85 0 -69 2 -75 31 -99 33 -28 58 -33 77 -14 22 22 14 46 -15 50 -27 3 -28 5 -31 75 l-3 72 28 3 c22 2 28 8 28 28 0 20 -6 26 -27 28 -24 3 -28 8 -28 33 0 24 -4 29 -23 29 -13 0 -27 -3 -30 -7z" class="svg-elem-17"></path>
                 <path fill="grey" d="M3409 524 c-31 -32 -34 -41 -34 -92 0 -66 20 -101 73 -126 41 -20 51 -20 91 0 20 10 31 22 29 33 -3 13 -14 15 -59 13 -50 -3 -57 -1 -72 22 -10 14 -17 28 -17 31 0 3 34 5 75 5 90 0 105 14 79 76 -34 82 -106 99 -165 38z m105 -27 c25 -18 10 -44 -30 -50 -62 -10 -80 7 -44 43 23 23 49 26 74 7z" class="svg-elem-18"></path>
                 <path fill="grey" d="M4233 543 c-42 -29 -18 -67 27 -43 27 14 70 5 70 -15 0 -17 -22 -30 -69 -40 -89 -19 -97 -101 -13 -144 19 -10 28 -9 47 3 15 10 35 14 53 10 38 -8 41 2 33 113 -5 77 -10 95 -28 113 -27 24 -88 26 -120 3z m103 -127 c10 -39 -12 -68 -49 -64 -43 4 -51 43 -13 58 14 5 26 14 26 20 0 21 30 9 36 -14z" class="svg-elem-19"></path>
                 <path fill="grey" d="M4574 549 c-4 -7 -21 -9 -45 -5 l-39 6 0 -120 0 -120 30 0 29 0 3 93 3 92 40 0 40 0 1 -88 c1 -96 9 -115 43 -98 18 10 21 21 21 86 0 110 -29 165 -86 165 -18 0 -36 -5 -40 -11z" class="svg-elem-20"></path>
                 <path fill="grey" d="M4828 543 c-52 -27 -25 -62 31 -42 30 11 71 -1 71 -20 0 -9 -33 -24 -80 -37 -42 -11 -60 -34 -60 -75 0 -32 5 -40 37 -59 35 -20 39 -21 63 -6 15 8 40 14 56 13 l29 -2 0 101 c0 96 -1 103 -25 123 -29 24 -82 26 -122 4z m100 -137 c4 -36 -14 -56 -50 -56 -44 0 -48 27 -7 61 40 33 53 32 57 -5z" class="svg-elem-21"></path>
                 <path fill="grey" d="M5110 530 c-30 -28 -32 -34 -25 -73 10 -59 6 -146 -8 -167 -28 -46 51 -108 120 -95 35 6 47 13 83 49 51 50 3 106 -91 106 -46 0 -59 3 -59 15 0 10 10 15 31 15 17 0 44 8 60 17 29 17 55 64 45 81 -3 5 1 18 9 28 8 10 15 27 15 37 0 15 -10 17 -74 17 -68 0 -76 -2 -106 -30z m81 -20 c21 -12 25 -50 9 -81 -13 -23 -28 -24 -52 -2 -22 20 -23 51 -1 75 19 21 19 21 44 8z m30 -210 c10 -6 19 -17 19 -25 0 -35 -93 -46 -113 -13 -24 37 44 64 94 38z" class="svg-elem-22"></path>
                 <path fill="grey" d="M5390 523 c-31 -32 -34 -42 -35 -92 0 -65 20 -100 73 -125 41 -20 51 -20 91 0 20 10 31 22 29 33 -3 13 -14 15 -59 13 -50 -3 -57 -1 -72 21 -9 14 -13 27 -10 31 4 3 37 6 74 6 84 0 98 15 73 77 -34 82 -104 98 -164 36z m106 -27 c26 -20 8 -49 -32 -51 -55 -3 -70 17 -38 49 19 20 44 20 70 2z" class="svg-elem-23"></path>
                 <path fill="grey" d="M5656 538 c-8 -30 -8 -202 0 -222 7 -19 30 -21 41 -3 4 6 6 40 4 75 -3 79 4 101 37 113 22 9 30 7 44 -11 15 -18 17 -34 12 -94 -5 -59 -4 -74 10 -85 13 -11 19 -11 31 -1 12 10 14 27 9 79 -8 86 4 113 49 109 l32 -3 3 -92 3 -93 29 0 30 0 0 89 c0 75 -3 93 -23 122 -26 39 -75 51 -97 25 -16 -20 -56 -21 -64 -1 -7 19 -50 19 -80 0 -21 -14 -24 -14 -36 0 -17 21 -27 19 -34 -7z" class="svg-elem-24"></path>
                 <path fill="grey" d="M6117 523 c-33 -33 -37 -42 -37 -90 0 -65 19 -97 71 -124 47 -24 67 -24 98 0 39 30 22 51 -36 44 -50 -5 -67 3 -75 37 -2 12 12 16 72 20 l75 5 3 36 c2 29 -3 42 -31 72 -46 51 -89 51 -140 0z m101 -25 c24 -24 14 -45 -25 -51 -42 -7 -53 -3 -53 23 0 33 54 52 78 28z" class="svg-elem-25"></path>
                 <path fill="grey" d="M6377 443 c-7 -131 1 -155 42 -133 20 10 21 16 16 83 -5 54 -3 77 7 91 14 18 62 25 76 11 4 -4 6 -43 4 -88 -2 -92 7 -119 35 -101 16 10 18 25 16 109 -2 86 -6 101 -25 122 -22 24 -72 31 -83 13 -3 -5 -17 -10 -30 -10 -13 0 -27 5 -30 10 -16 26 -22 1 -28 -107z" class="svg-elem-26"></path>
                 <path fill="grey" d="M7310 535 c0 -14 4 -33 10 -43 5 -9 19 -39 30 -67 12 -27 26 -59 31 -71 6 -11 9 -34 7 -50 -2 -23 -10 -31 -38 -40 -27 -9 -36 -18 -38 -38 -3 -24 0 -26 31 -26 27 0 42 8 66 35 17 20 31 44 31 55 0 10 5 22 10 25 6 3 10 14 10 23 0 18 34 110 56 154 18 34 18 57 -1 65 -24 8 -52 -23 -59 -66 -3 -21 -13 -56 -22 -77 -19 -44 -19 -44 -49 61 -21 70 -31 85 -56 85 -14 0 -19 -7 -19 -25z" class="svg-elem-27"></path>
                 <path fill="grey" d="M7622 537 c-32 -34 -29 -81 7 -106 16 -12 36 -21 44 -21 21 0 40 -27 33 -45 -5 -13 -18 -15 -63 -13 -44 2 -58 0 -58 -11 0 -8 7 -17 15 -21 8 -4 25 -12 37 -18 34 -18 53 -15 89 13 62 48 51 98 -31 136 -70 33 -56 63 24 52 46 -6 53 5 21 37 -29 29 -90 27 -118 -3z" class="svg-elem-28"></path>
                 <path fill="grey" d="M8085 542 c-55 -47 -71 -126 -37 -184 17 -30 77 -68 108 -68 9 0 29 9 45 21 40 28 24 52 -31 43 -44 -7 -80 9 -80 36 0 17 9 19 73 22 71 3 72 3 75 32 4 34 -13 73 -43 98 -26 22 -84 22 -110 0z m79 -38 c9 -3 16 -16 16 -29 0 -18 -7 -24 -37 -28 -42 -7 -53 -3 -53 23 0 30 39 47 74 34z" class="svg-elem-29"></path>
                 <path fill="grey" d="M8337 553 c-3 -5 -7 -59 -8 -121 -3 -122 4 -142 41 -122 19 10 22 19 20 78 -3 97 -2 102 23 111 45 17 57 -2 57 -92 0 -88 9 -114 34 -104 13 5 16 23 16 90 0 85 4 97 42 110 34 12 50 -21 48 -97 -3 -91 -3 -93 16 -100 36 -14 45 11 38 113 -5 77 -11 100 -27 119 -24 26 -82 31 -91 7 -3 -8 -17 -15 -31 -15 -14 0 -28 7 -31 15 -7 17 -59 21 -69 5 -8 -13 -48 -13 -62 1 -6 6 -13 7 -16 2z" class="svg-elem-30"></path>
                 <path fill="grey" d="M2847 543 c-4 -3 -7 -57 -7 -119 0 -104 1 -112 20 -117 24 -6 53 20 46 40 -3 8 -6 57 -6 109 0 92 -1 94 -23 94 -13 0 -27 -3 -30 -7z" class="svg-elem-31"></path>
                 </g>
                 </svg>
                
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1 mt-5">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf='isLogin'>

                <div class="col d-flex align-items-center justify-content-center justify-content-lg-start">
                    <!-- <p class="lead fw-normal mb-0 me-3">Sign in with</p>
                    <button type="button" class="btn btn-primary btn-floating mx-1">
                        <i class="bi bi-facebook"></i>
                    </button>
        
                    <button type="button" class="btn btn-primary btn-floating mx-1">
                        <i class="bi bi-twitter"></i>
                    </button>
        
                    <button type="button" class="btn btn-primary btn-floating mx-1">
                        <i class="bi bi-linkedin"></i>
                    </button> -->
                    <asl-google-signin-button type="standard" size="large"></asl-google-signin-button>
                </div>
                <div class="divider d-flex align-items-center my-4">
                     <div class="form-check">
                        <input type="checkbox" class="form-check-input"  ng-checked="isShowLoginInput" [(ngModel)]="isShowLoginInput" [ngModelOptions]="{standalone:true}"   placeholder="Show Login Input">
                        <label class="form-check-label"  >
                           Or with username and password
                            </label>
                        </div>
                     
                </div> 
                <div class="mb-3" *ngIf="isShowLoginInput">
                    <label for="username" class="form-label">Username</label>
                    <input type="email" formControlName="username" class="form-control" placeholder="Email address" maxlength="50"
                        [ngClass]="{ 'is-invalid': submitted && loginForm.controls['username'].errors }">

                    <div *ngIf="submitted && loginForm.controls['username'].errors" class="text-danger">
                        <div *ngIf="loginForm.controls['username'].errors['required']">Username is required</div>
                        <div *ngIf="loginForm.controls['username'].errors['email']">Username is not valid</div>
                    </div>
                </div>
                <div class="mb-3" *ngIf="isShowLoginInput">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" formControlName="password" class="form-control" placeholder="Password" maxlength="20"
                        [ngClass]="{ 'is-invalid': submitted && loginForm.controls['password'].errors }">

                    <div *ngIf="submitted && loginForm.controls['password'].errors" class="text-danger">
                        <div *ngIf="loginForm.controls['password'].errors['required']">Password is required</div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center" *ngIf="isShowLoginInput">
                    <!-- <div class="form-check mb-0">
                        <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                        <label class="form-check-label mt-0" for="form2Example3">
                        Remember me
                        </label>
                    </div> -->
                </div>
                
                <div class="mt-4 pt-2 d-flex align-items-center" *ngIf="isShowLoginInput">
                    <div class="col-5 col-sm-5">
                        <a role="button" class="text-decoration-none link-primary" (click)='forgotPasswordLinkClick()' *ngIf='isLogin'>Forgot password?</a>
                    </div>
                    <div class="col-7 col-sm-7 d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary btn-lg ps-4 pe-4 btn-sm" (keyup.enter)="onSubmit()">Login</button>
                    </div>
                    <!-- <p class="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                        class="link-danger text-decoration-none">Register</a></p> -->
                </div>
                <div class="d-flex flex-row align-items-center" *ngIf="isVPNConnectivityIssue">
                    <div class="p-2"> Hi there, Please verify your computer is connected to the Macmillan Learning VPN network! </div>
                </div>
                <div class="d-flex flex-row align-items-center" *ngIf="isVPNConnectivityIssue">
                    <div class="p-2 blink"> Check VPN Connectivity</div>
                   <div class="p-2  blink">
                    <img style="width: 6rem;" src="https://theme.zdassets.com/theme_assets/9993670/d1f54e49361008fb906d2b477dae4310cb866643.svg" alt="OpenVPN"/>
                    </div> 
                    <div class="p-2  blink">
                        <svg version="1.0" style="width: 6rem;" viewBox="0 0 99.000000 31.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,31.000000) scale(0.100000,-0.100000)" fill="white" stroke="none"> <path fill="green" d="M75 240 l-28 -29 61 -63 c50 -51 62 -59 62 -42 0 13 -19 39 -46 63 -37 35 -43 45 -33 57 15 18 33 18 48 0 8 -10 9 -16 1 -21 -15 -9 -12 -25 4 -25 27 0 30 40 5 66 -29 31 -39 30 -74 -6z"/> 
                            <path fill="green" d="M220 161 c0 -28 5 -51 10 -51 6 0 10 6 10 13 0 7 10 17 23 22 14 7 22 19 22 35 0 21 -5 26 -32 28 l-33 3 0 -50z"/> <path fill="green" d="M374 194 c3 -9 6 -31 6 -50 0 -25 4 -34 16 -34 11 0 14 5 10 16 -3 9 -6 31 -6 50 0 25 -4 34 -16 34 -11 0 -14 -5 -10 -16z"/> <path fill="green"  d="M573 194 c-6 -16 13 -38 40 -47 6 -2 7 -9 3 -15 -4 -7 -16 -10 -27 -7 -11 4 -19 2 -19 -4 0 -19 57 -13 65 7 6 16 7 16 16 0 6 -11 21 -18 37 -17 21 1 22 2 7 6 -34 8 -41 23 -11 23 21 0 27 4 24 18 -4 23 -58 30 -58 8 0 -20 -5 -20 -35 0 -25 17 -24 40 1 30 8 -3 14 -1 14 4 0 17 -50 11 -57 -6z"/> 
                            <path fill="green" d="M433 183 c-19 -7 -16 -30 5 -42 16 -10 16 -10 0 -11 -10 0 -18 -4 -18 -10 0 -5 9 -10 20 -10 28 0 38 30 14 43 -18 10 -18 10 1 17 14 5 15 8 4 12 -8 3 -20 3 -26 1z"/> 
                            <path fill="green" d="M496 177 c-28 -20 -18 -61 17 -65 32 -4 37 15 5 21 -20 4 -19 4 5 6 31 1 34 12 11 35 -19 19 -16 19 -38 3z"/> 
                            <path fill="green" d="M736 177 c-11 -8 -17 -23 -14 -38 2 -19 10 -25 33 -26 27 -2 28 -2 8 7 -27 11 -31 47 -5 52 11 2 13 6 6 10 -6 4 -19 2 -28 -5z"/> 
                            <path fill="green" d="M302 148 c2 -28 8 -34 31 -36 25 -3 27 -1 27 32 0 20 -4 36 -10 36 -5 0 -10 -11 -10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 -5 25 -11 25 -6 0 -9 -14 -7 -32z"/> 
                            <path fill="green" d="M784 164 c3 -9 6 -24 6 -35 0 -14 7 -19 30 -19 28 0 30 3 30 35 0 19 -4 35 -10 35 -5 0 -10 -14 -10 -31 0 -38 -24 -35 -28 4 -2 15 -8 27 -14 27 -5 0 -7 -7 -4 -16z"/> 
                            <path fill="green" d="M860 145 c0 -19 5 -35 10 -35 6 0 10 10 10 23 0 13 5 28 12 35 9 9 7 12 -10 12 -18 0 -22 -6 -22 -35z"/> 
                            <path fill="green" d="M912 148 c3 -29 7 -33 33 -35 18 -1 22 1 10 4 -34 9 -41 23 -11 23 38 0 32 34 -7 38 -27 3 -28 1 -25 -30z"/> 
                            <path fill="black" d="M55 119 c-4 -6 6 -25 24 -42 25 -25 35 -30 48 -21 29 18 29 38 0 30 -20 -5 -30 0 -45 19 -14 17 -23 22 -27 14z"/> </g> </svg>   </div> 
                </div>
            </form>
            <div class="text-center" *ngIf='!isLogin'>
                <h3><i class="fa fa-lock fa-4x"></i></h3>
                <h2 class="text-center">Forgot Password?</h2>
                <p>You can reset your password here.</p>
                <div class="panel-body mt-5">
                  <form role="form" autocomplete="off" method="post">
                    <div class="mb-3">
                        <input type="email" [formControl]="forgotemail" class="form-control" placeholder="Email address">
                        <div *ngIf="forgotPwdsubmitted && forgotemail.errors" class="text-danger text-start">
                            <div *ngIf="forgotemail.errors['required']">Username is required</div>
                            <div *ngIf="forgotemail.errors['email']">Username is not valid</div>
                        </div>
                    </div>
                    <div class="mt-5 pt-2 d-flex align-items-center">
                        <div class="col-5 col-sm-5 d-flex align-items-start">
                            <a role="button" class="text-decoration-none link-primary" (click)='loginLinkClick()' *ngIf='!isLogin'>Login</a>
                        </div>
                        <div class="col-7 col-sm-7 d-flex justify-content-end">
                            <button type="submit" class="btn btn-primary btn-lg ps-4 pe-4 btn-sm" (click)="forgotPassword()">Reset Password</button>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
        </div>
    </div>
</div>
<app-bs-footer></app-bs-footer>
