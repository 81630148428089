<form>
	<p-table [columns]="tableColumns" [value]="tableData" [styleClass]="tableSize" [tableStyle]="{ 'min-width': '50rem' }"  
	[paginator]="paginator"
	[rows]="pageSize"
	[showCurrentPageReport]="showCurrentPageReport"
	[currentPageReportTemplate]="currentPageReportTemplate"
	[rowsPerPageOptions]="rowsPerPageOptions"
	selectionMode="single"
	[resizableColumns]="true" 
	columnResizeMode="expand"
	[lazy]="false"
	(onLazyLoad)="loadData($event)"
	[totalRecords]="totalRecords"
	[scrollHeight]="scrollHeight">
        <ng-template pTemplate="header" let-columns>
            <tr>
				<ng-container *ngFor="let col of columns">
					<th [ngStyle]="{'width': col.width }" *ngIf="col.sortable" [pSortableColumn]="col.field" >{{ col.header }} <p-sortIcon field="col.header"></p-sortIcon></th>
					<th [ngStyle]="{'width': col.width }" *ngIf="!col.sortable">{{ col.header }}</th>
				</ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
				<ng-container *ngFor="let col of columns">
					<td>
						<span *ngIf="col.field != 'action'">{{rowData[col.field]}}</span>
						<span *ngIf="col.field === 'action'"><i class="fs-6 fa-solid fa-lock-open"></i></span>
					</td>
				</ng-container>
            </tr>
        </ng-template>
    </p-table>
</form>
