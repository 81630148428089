import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDetails } from 'src/app/core/models/authentication';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AdminService } from '../../admin/services/admin.service';
import { LoaderService } from 'src/app/shared/loader/services/loader.service';
import { finalize, Subscription } from 'rxjs';
import { SocialUser, SocialAuthService, GoogleLoginProvider  } from '@abacritt/angularx-social-login';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  isVPNConnectivityIssue:boolean=false;
  isShowLoginInput:boolean=false;
  loginForm!: FormGroup
  userData?: UserDetails;
  isLogin: boolean = true;
  submitted: boolean = false;
  forgotPwdsubmitted: boolean = false;
  forgotemail!: FormControl;
  socialUser!: SocialUser;
  subscriptions: Subscription[] = [];
  constructor(private formBuilder: FormBuilder, 
    private router: Router, 
    private authenticationService: AuthenticationService,
    private adminService: AdminService,
    private loaderService: LoaderService,
    private socialAuthService: SocialAuthService,
    private authService:AuthService,    
    private messageService: MessageService,
    ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      password: ['', [Validators.required]]
    });
    this.forgotemail = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern("^[a-zA-Z0-9._%+'-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"),
    ]);

    let subscription = this.socialAuthService.authState.subscribe((user) => {   
      this.socialUser = user; 
      if(this.socialUser) {
        localStorage.setItem('socialUserIdToken', this.socialUser.idToken);
        this.login(this.socialUser.email, this.socialUser.idToken, "google");
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if(!s.closed) {
        s.unsubscribe();
      }
    });
  }

  onSubmit() {
    this.isVPNConnectivityIssue=false;
    this.submitted = true;
    if(this.loginForm.valid) {
      this.loaderService.setLoading(true);
      this.login(this.loginForm?.controls["username"].value, this.loginForm?.controls["password"].value, "");
    }
  }

  login(userName: string, password: string, idTokenHint: string) {
    this.loaderService.setLoading(true);
    this.authenticationService
    .gettoken(userName, password, idTokenHint)
    .subscribe( {next: (response) => {
      localStorage.setItem('tmsUserAccessToken', response['access_token']);
      localStorage.setItem('tmsUserAccessTokenExpireIn',String( response['expires_in']));
      localStorage.setItem('tmsUserAccessRefreshToken', response['refresh_token']);
      localStorage.setItem('tmsUserAccessLoginTime',String( new Date().valueOf()) );
      this.getAccountInformation(response['access_token']);     
    },
    error:(error) => {
      if(error==undefined){ 
      console.log("error : Unknown Error");
      }
      else if(error?.data){
        switch(error.data.responseCode){
          case "ERR401": 
          case "ERR402": 
          case "ERR403": this.messageService.add({ severity: 'error', summary: 'Error', detail: error.data.message});
            break;
          case "ERRVPN": this.isVPNConnectivityIssue=true;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.data.message});
            break;
          default:   this.messageService.add({ severity: 'error', summary: 'Error', detail: error.data.message});
            break;
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Unknown error. Please Contact Administrator. ("+error?.message+")"});
           
      }
      
      console.log("error :" + error?.message);
    }});
  }

  getAccountInformation(token: string) {
    if (token === undefined) {
      return;
    }
    var username = this.loginForm.get("username")?.value ? this.loginForm.get("username")?.value : this.socialUser?.email;
    this.authenticationService
    .getAccountInformation(token, username)
    .pipe(
      finalize(() => {
        this.loaderService.setLoading(false);
      })
    )
    .subscribe( {next: (response) => {
      if (response) {
        this.userData = response['userdetails']; 
        localStorage.setItem('tms.user_details',JSON.stringify(this.userData));
        this.authService.loadLoginUserPermissions();
        if(this.userData.requirepasswordchangeonlogin == "yes") {
          this.router.navigate(['/resetpassword'], { queryParams: { } });
        }
        this.router.navigate(['/product']);
      } 
    },
    error:(error) => {
      console.log("error :" + error?.message);
    }});
  }

  forgotPasswordLinkClick() {
    this.isLogin = false;
    this.forgotPwdsubmitted = false;
  }
  loginLinkClick() {
    this.isLogin = true;
    this.submitted = false;
  }

  forgotPassword() {
    this.loaderService.setLoading(true);
    this.forgotPwdsubmitted = true;
    if(this.forgotemail.valid) {
      this.adminService.getForgotPassword(this.forgotemail.value!)
      .pipe(
        finalize(() => {
          this.loaderService.setLoading(false);
        })
      )
      .subscribe({next: (response) => {
        this.isLogin = true;
      },
      error:(error) => {
        console.log("error :" + error.message);
      }});
    }
  } 
}
