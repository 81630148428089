import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Token, UserProfileModel } from '../models/authentication';
import { GlobalConstants } from './globalconstants.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public http: HttpClient,
    private baseService: BaseService,
    private globalConstants: GlobalConstants
  ) { }

  public gettoken(userName: string, password: string, idTokenHint: string): Observable<Token> {
    var config = {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };
    var requestdata =
      'grant_type=password&username=' + userName + '&password=' + password + '&IdTokenHint=' + idTokenHint;
      return this.http.post<Token>(
      this.baseService.baseUrl + this.globalConstants.TOKEN_URL,
      requestdata,
      config
    );
  }

  public getAccountInformation(token: string, userName: string): Observable<UserProfileModel> {
    var header = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };

    return this.http
      .get<UserProfileModel>(this.baseService.baseUrl + this.globalConstants.USERDETAILS_URL.replace('#username',userName), header)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message
    return throwError(() => error);
  }
}
