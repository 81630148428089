import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LoaderService } from '../loader/services/loader.service'; 
import { map,Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaService } from 'src/app/core/services/media.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface  UploadMediaResponse{
  isFileUploaded: true,
  message: string,
  fileName: string,
  physicalFileName: string,
  fileSize: string,
  fileExtention: string,
  fileUrl: string
}
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [MessageService]
})
export class FileUploadComponent implements OnInit {
  // Maximum file size allowed to be uploaded = 1MB
  @Input() maxSize: number = 1048576; 
  @Input() targetFolder: string = "";  
  @Input() targetFolderUrlKeyName: string = "";   
  @Input() acceptFileExtesions: string = ".jpg,.jpeg,.png";  
  @Input() allowMultiFileupload:boolean=false; 
  @Output() fnGetMediaUploadDataEvent = new EventEmitter<Array<UploadMediaResponse>>();
  private modalRef!: NgbModalRef;
  uploadedMedia: Array<any> = [];
  uploadResponses:Array<UploadMediaResponse>=[]
  theFile: any = null;
  uploadedFileName:string="";  
  fileUploadForm!: FormGroup ; 
  constructor( 
    private loaderService: LoaderService, 
    public modalService: NgbModal,
    private formBuilder: FormBuilder, 
    private messageService: MessageService,
    private mediaService: MediaService  ) { 
  }
  ngOnInit(): void {  
    this.fileUploadForm = this.formBuilder.group({
      files:['']  
    });
  
  }
  sendFormData() {
    this.fnGetMediaUploadDataEvent.emit(this.uploadResponses);
  }
  onFileBrowse(event: Event,viewFileUploadProgressModal:any) {
    const target = event.target as HTMLInputElement;
    this.openViewFileUploadProgressModal(viewFileUploadProgressModal)
    this.processFiles(target.files);
  }
  openViewFileUploadProgressModal(content: any) { 
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'xl'
    };   
        this.modalRef = this.modalService.open(content, ngbModalOptions); 
     
  }
  closeModel() { 
    this.modalRef.dismiss();
  }
  processFiles(files:any) {
    for (const file of files) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        var fileSize:number= this.mediaService.getFileSize(file.size);
        let fileSizeUnit:string= this.mediaService.getFileSizeUnit(file.size)
        if(this.maxSize >=fileSize){// file size is acceptable
          this.uploadedMedia.push({
            FileName: file.name,
            FileSize:String( fileSize )+ ' ' +fileSizeUnit ,
            FileType: file.type,
            FileUrl: event.target.result,
            FileProgessSize: 0,
            FileProgress: 0,
            IsFileUploaded:false,
            ServerMessage:"",
            ngUnsubscribe: new Subject<any>(),
          });
          //start processing
          this.startProgress(file, this.uploadedMedia.length - 1);
        }else{
          // file size is exceed
        }
        
      };
      if(!this.allowMultiFileupload) break;
    }
  }

  async startProgress(file:any, index:number) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) {
      let fileSize = this.mediaService.getFileSize(file.size);
      let fileSizeInWords = this.mediaService.getFileSizeUnit(file.size);
      if (this.mediaService.isApiSetup) {
        let formData = new FormData();
        formData.append('file', file); 
        formData.append('targetFolderName',  this.targetFolder);
        formData.append('targetFolderUrlKeyName',  this.targetFolderUrlKeyName);

        this.mediaService
          .uploadMedia(formData)
          .pipe(//takeUntil(file.ngUnsubscribe)
          )
          .subscribe({
            next:
            (res: any) => {
              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);
                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;
              } else if (res.status === 'completed') {
                filteredFile.Id = res.Id;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }
              else if (res.isFileUploaded) {
                this.uploadResponses.push(res);
                this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
                this.sendFormData();
                //reset form and data;
                this.fileUploadForm.reset();
                this.uploadedMedia=[];
                this.closeModel();
              }if (res.isFileUploaded==false) {
                this.uploadResponses.push(res);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: res.message });
                filteredFile.IsFileUploaded =res.isFileUploaded;
                filteredFile.ServerMessage =res.message;
                //reset form and data;
                this.fileUploadForm.reset(); 
              }
            },
           error: (error: any) => {
              console.log('file upload error');
              console.log(error);
            }
           });
      }  
      else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }
  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  removeImage(idx: number) {
    this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);
  }
}
