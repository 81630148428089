import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/components/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { ResetPasswordComponent } from './features/resetpassword/components/reset-password/reset-password.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './shared/loader/components/loader.component';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgbTypeaheadModule,
    SocialLoginModule,
    GoogleSigninButtonModule
  ],
  entryComponents:[LoginComponent],
  providers: [
    DatePipe,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('24366351587-965t5bsr9l4mvjnts1iok7o36slp688o.apps.googleusercontent.com')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
