import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component,  Input,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, interval, map, shareReplay, takeWhile, timer } from 'rxjs';
interface timeComponents {
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;
}
@Component({
  selector: 'app-bs-header',
  templateUrl: './bs-header.component.html',
  styleUrls: ['./bs-header.component.css']
})
export class BsHeaderComponent implements OnInit {
  name: string = '';
  @Input() lockedList: any =  [];
  loginExpireIn:number=0;
  loginTime:number=0; 
  //public timeRemaining$: Observable<timeComponents>;
  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService,
    private sharedService: SharedService
  ) { 
    this.loginExpireIn=Number(localStorage.getItem('tmsUserAccessTokenExpireIn'));
    this.loginTime= Number(localStorage.getItem('tmsUserAccessLoginTime'));
    // this.timeRemaining$ = interval( 1000).pipe(
    //   map((n) =>  this.calcDateDiff()),
    //   shareReplay(1),
    // );
  }

  ngOnInit(): void {
    const userJson = localStorage.getItem('tms.user_details');
    if (userJson != undefined || userJson != null) {
      let user = JSON.parse(userJson);
      this.name = user.firstName + " " + user.lastName;
      this.isbnLockedList(user.id);
    }
   
    this.autoLogoutWhenTokenExpine()
   
  }
  autoLogoutWhenTokenExpine(){
    let endDay: Date = new Date(this.loginTime+(this.loginExpireIn*1000))
    let startDay: Date = new Date();
    setTimeout(()=>{ localStorage.clear();
      this.socialAuthService.signOut();
      this.router.navigateByUrl('/login');},  endDay.valueOf()-startDay.valueOf() );

  }
  
    
  calcDateDiff(): timeComponents { 
    
    let endDay: Date = new Date(this.loginTime+(this.loginExpireIn*1000))
    const dDay = endDay.valueOf();
  
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
  
    const timeDifference = dDay - Date.now();
  
    const daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
    );
  
    const hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
    );
  
    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
    );
  
    const secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
  
    return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
  }
  isbnLockedList(currentUserId:number) {
    this.sharedService.getIsbnLockedList(currentUserId)
    .subscribe({
      next: (response) => {
        if (response) {
          this.lockedList = response.results; 
        } 
      },
    error: (err: HttpErrorResponse) => console.log(err?.message)
   });
  }

  signOut() {
    localStorage.clear();
    this.socialAuthService.signOut();
    this.router.navigateByUrl('/login');
  }
  onGotoUserGuide(){
    window.open( "https://docs.google.com/document/d/1L368VN4M6Y3WMKAZw2NvStd-1diKIPgfycvuePmEEaE", "_blank");
  }
  toggleSideBar() {
    this.sharedService.isToggleSidebar = this.sharedService.isToggleSidebar ? false : true;
  }

}
