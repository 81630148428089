import { Component } from '@angular/core';

@Component({
  selector: 'app-bs-sidebar-admin',
  templateUrl: './bs-sidebar-admin.component.html',
  styleUrls: ['./bs-sidebar-admin.component.css']
})
export class BsSidebarAdminComponent {
  isToggleSidebar: boolean = false;

  toggleSideBar() {
    this.isToggleSidebar = this.isToggleSidebar ? false : true;
    console.log(this.isToggleSidebar);
  }
}
