import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs';
import { GlobalConstants } from 'src/app/core/services/globalconstants.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MetadataService } from 'src/app/features/admin/metadata/services/metadata.service';
import { LoaderService } from 'src/app/shared/loader/services/loader.service';

@Component({
  selector: 'app-show-help',
  templateUrl: './show-help.component.html',
  styleUrls: ['./show-help.component.css']  
   
})
export class ShowHelpComponent implements OnInit{
@Input() screenName:string="";
@Input() viewName:string="";
@Input() tabName:string="";
 htmlHelpDetails:string="";
 
 helpForm!:FormGroup;
  private modalRef!: NgbModalRef;
  currentUser:string = '';
      //---angular editor configuration
      editorConfig: AngularEditorConfig = {
        editable: false,
          spellcheck: true,
          height: 'auto',
          minHeight: '0',
          maxHeight: 'auto',
          width: 'auto',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: false,
          placeholder: 'Enter text here...',
          defaultParagraphSeparator: '',
          defaultFontName: '',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
          customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
        uploadUrl: 'v1/image',
       // upload: (file: File) => { .... },
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
        ]
    };
    //--end angular editor configuration
  constructor( 
    private service:MetadataService, 
    private formBuilder: FormBuilder, 
    private globalConstant: GlobalConstants,
    private loaderService: LoaderService,
    public utilityService: UtilService,
    public modalService: NgbModal,
    private messageService: MessageService ){
      const userJson = localStorage.getItem('tms.user_details');
      const user = userJson !== null ? JSON.parse(userJson) : undefined;
      this.currentUser = user !== undefined ? user.username : '';
    }
    ngOnInit(): void { 
      this.htmlHelpDetails="Help Detail Loading......";
      this.helpForm= this.formBuilder.group({
        htmlHelpDetails:this.htmlHelpDetails
      }); 
    }
    openHelpModal(content: any) { 
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size: 'xl'
      };  this.htmlHelpDetails="Help Detail Loading......";
      if(!this.utilityService.isNullOrEmpty( this.screenName) && !this.utilityService.isNullOrEmpty( this.viewName)){
         this.getHelpDetails();
     } else{
      this.htmlHelpDetails="No help found.";
      this.messageService.add({ severity: 'warn', summary: 'Waring', detail: 'No help found' });
      
    }
      this.modalRef = this.modalService.open(content, ngbModalOptions); 
    }
    closeModel() { 
      this.modalRef.dismiss();
    }
    getHelpDetails( ){
      this.loaderService.setLoading(true);
      this.service.getHelpMetadataByScreenViewTab(this.screenName,this.viewName,this.tabName)
      .pipe(
        finalize(() => {
          this.loaderService.setLoading(false);
        })
      )
      .subscribe({
        next: (response) => {
          if (response.status) {
            this.htmlHelpDetails="";
            for (const hd of  response.results) { 
              this.htmlHelpDetails+="<br/>"+hd.htmlText;
            }
            this.helpForm.patchValue({
              htmlHelpDetails:this.htmlHelpDetails
            }); 
            this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });
          }else {
            this.htmlHelpDetails="No help found.";
            this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
          }
        },
        error: (err: HttpErrorResponse) =>  { 
          console.log(err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Occurred.' });
        }
      });
    }
}
