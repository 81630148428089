import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  isNullOrEmpty(obj: any) : boolean{
    if(obj == null || obj == undefined || obj =="")
      return true;
    return false;
  }
  isObject(obj:any):boolean{
    if (
      typeof obj === 'object' &&
      !Array.isArray(obj) &&
      obj !== null
  ) {
      return true;
  }
  else return false;
  }
  stringToBoolean(str: string): boolean {
    switch(str.trim().toLowerCase()) {
        case "true":
        case "yes":
        case "1":
            return true;
        case "false":
        case "no":
        case "0":
        case "" :
            return false;
        default:
            throw new Error("Invalid input");
    }
  }
}
