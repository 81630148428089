import { OnChanges,SimpleChanges, Component,Input,Output, EventEmitter,OnInit } from '@angular/core';
import { MessageService } from 'primeng/api'; 
import { AngularEditorConfig  } from '@kolkov/angular-editor';
import { Column, ControlTypeModel,fnControlTypeData, GroupMetadata, Metadata } from '../../features/admin/metadata/models/metata.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { MetadataService } from '../../features/admin/metadata/services/metadata.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/core/services/master.service';
import { LoaderService } from 'src/app/shared/loader/services/loader.service';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, finalize, map, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http'; 
import { UtilService } from 'src/app/core/services/util.service'; 
interface FormValidators {
  min?: number;
  max?: number;
  required?: boolean;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  nullValidator?: boolean;
}
interface FormControlOptions {
  min?: string;
  max?: string;
  step?: string;
  icon?: string;
}
interface FormControls {
  name: string;
  label: string;
  value: string;
  type: string;
  options?: FormControlOptions;
  required: boolean;
  validators: FormValidators;
}
export interface FormSettingData{
  controls: FormControls[];
}

@Component({
  selector: 'app-metadata-group',
  templateUrl: './metadata-group.component.html',
  styleUrls: ['./metadata-group.component.css'], 
  providers: [MessageService] 
})
export class MetadataGroupComponent implements OnInit,OnChanges  {
  @Input() parentRecordId:number=0;
  @Input() recordId:number=0;
  @Input() metadataGroupCode:string="";
  @Input() formSettingData: any;
  @Input() formSetData: any;
  @Input() formEventPropagation: boolean=false;
  
  @Input() allowMultipleChildRecord: boolean=false;
  @Input() backButton: boolean=false;
  @Input() formEventPropagationAfterSave: boolean=false;
  @Output() formGetDataEvent = new EventEmitter<string>();  
  @Output() formGetDataAfterSaveEvent = new EventEmitter<string>();  
  @Output() goBackEvent = new EventEmitter<string>(); 
  groupName_Code:string=""
  cols!: Column[];
  verror:Array<string>=[];//validation error
  selectedGroupId:number=0;//its add mode
  controlTypes!:Array<ControlTypeModel>;
  selectedType:string="Text";  
  groupMetadataDetail!: GroupMetadata|null;   
  tempGroupMetadataDetail!: GroupMetadata|null;  
  selectedMedatas:Array<Metadata>=[];  

  currentUser = ''; 
  groupForm!:FormGroup;
  groupTotalRecords: number = 0;
  groupPageSize: number = 5;
  groupPage = 1; 
  isFormGenerated:boolean=false;
  //---angular editor configuration
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
   // upload: (file: File) => { .... },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};
//--end angular editor configuration
  constructor(private route: ActivatedRoute, 
    private utlService:UtilService, 
    private service:MetadataService, 
     private formBuilder: FormBuilder,
    private masterService: MasterService,
    private loaderService: LoaderService,
    public modalService: NgbModal,
    private messageService: MessageService, public router:Router ){
      const userJson = localStorage.getItem('tms.user_details');
      const user = userJson !== null ? JSON.parse(userJson) : undefined;
      this.currentUser = user !== undefined ? user.username : '';
    }

    ngOnInit(): void { 
  
      this.cols = [
                  { field: '', header: 'Edit' },
                  { field: 'id', header: 'Id' },
                  { field: 'name', header: 'Name' },
                  { field: 'label', header: 'Label' },
                  { field: 'value', header: 'Value' },
                  { field: 'type', header: 'Type' },
                  { field: 'options', header: 'Options' },
                  { field: 'validators', header: 'Validators' },
                  { field: 'isActive', header: 'Is Active' },
                  { field: 'isDeleted', header: 'Is Deleted' }
              ];
      this.controlTypes = fnControlTypeData(); 
      this.groupForm= this.formBuilder.group({}); 
      this.getGroupMetadataDynamicFormDetails(  );
    }
    ngOnChanges(changes: SimpleChanges) {
      if (changes['formSettingData'] &&  !changes['formSettingData'].isFirstChange()) { 
        this.createForm(this.formSettingData);
        if(this.isFormGenerated)
        this.groupForm.patchValue( this.formSetData ); 
      }
      if ((changes['parentRecordId'] &&  !changes['parentRecordId'].isFirstChange())  ||
          (changes['recordId'] &&  !changes['recordId'].isFirstChange())){  
         this.getGroupMetadataDynamicFormDetails(  );
      }
    }
    onSelectType(event:any){

    }
    createForm(gmd?: GroupMetadata) {
      if(gmd?.metadataDbMappingList!==undefined)
      {
        for (const mp of gmd?.metadataDbMappingList) {
        const validatorsToAdd = [];
        if(mp.metadataDetail!==undefined){
          for (const [key, value] of Object.entries(mp.metadataDetail.validators)) {
            switch (key) {
              case 'min':if( typeof value === "number")
                validatorsToAdd.push(Validators.min(value));
                break;
              case 'max':if( typeof value === "number")
                validatorsToAdd.push(Validators.max(value));
                break;
              case 'required':
                if (value) {
                  validatorsToAdd.push(Validators.required);
                }
                break;
              case 'requiredTrue':
                if (typeof value === 'boolean' ) {
                // validatorsToAdd.push(Validators.requiredTrue);
                }
                break;
              case 'email':
                if (typeof value === 'boolean' ) {
                //validatorsToAdd.push(Validators.email);
                }
                break;
              case 'minLength':if( typeof value === "number")
              validatorsToAdd.push(Validators.minLength(value));
                break;
              case 'maxLength':if( typeof value === "number")
                validatorsToAdd.push(Validators.maxLength(value));
                break;
              case 'pattern':
                //validatorsToAdd.push(Validators.pattern(value));
                break;
              case 'nullValidator':
                if (value) {
                // validatorsToAdd.push(Validators.nullValidator);
                }
                break;
              default:
                break;
            }
          }
        }
        switch(mp.metadataDetail.type){
          case 'Checkbox': { 
            let val=this.utlService.stringToBoolean( mp.metadataDetail.value)  ;
            this.groupForm.addControl(
              mp.metadataDetail.name, 
              this.formBuilder.control(val, validatorsToAdd)
            );
          }
          break;
          default:
            this.groupForm.addControl(
              mp.metadataDetail.name, 
              this.formBuilder.control(mp.metadataDetail.value, validatorsToAdd)
            );
            break;
        }
        
        }
        this.isFormGenerated=true;
      }
    }
    sendFormData(value: any) {
      this.formGetDataEvent.emit(value);
    }
    sendFormDataAfterSave(value: any) {
      this.formGetDataAfterSaveEvent.emit(value);
    }
    onGoBackClick(){
    this.goBackEvent.emit()
    }
    onSubmit() {
      console.log('Form valid: ', this.groupForm.valid);
      if(this.groupForm.valid ){
        if(this.formEventPropagation){
        this.sendFormData( this.groupForm.value);
        }else{
          //take copy of formSettingData and fill metadataDetail value
           let copyFormSettingData:any=Object.assign(this.formSettingData);
           let isSaveOperation:boolean=true;//insert operation
          for (const mp of  copyFormSettingData?.metadataDbMappingList) {
            mp.metadataDetail.value=this.groupForm.value[mp?.metadataDetail.name]; 
            if( mp?.metadataDetail.name==="id"){
              if(mp.metadataDetail.value==="")
                mp.metadataDetail.value=0;
              if(Number(mp.metadataDetail.value)>0)
               isSaveOperation=false;//update operation
            }
          }
           //save in database
           this.saveFormDataInDB(copyFormSettingData,isSaveOperation)
        }
      }
      
      else
      this.messageService.add({ severity: 'error', summary: 'Validation',detail: this.service.generateErrorMessages(this.groupForm)  });
       
    }
    onResetClick(){
      this.groupForm.reset();
      this.groupForm.patchValue( this.formSetData ); 
    }
    saveFormDataInDB(formSettingData:any, isSaveOperation:boolean){
      if(isSaveOperation)
        this.addGroupDbMetadata(formSettingData);
      else
      this.updateGroupDbMetadata(formSettingData);
    }
    fillEditForm(metadata:GroupMetadata){
      this.groupMetadataDetail = metadata;
      this.selectedMedatas=this.groupMetadataDetail.metadataDbMappingList??[]; 
      this.groupTotalRecords=this.selectedMedatas.length;
        this.selectedGroupId = this.groupMetadataDetail.id; 
        this.groupForm.patchValue({ 
          groupCode: this.groupMetadataDetail.groupCode ,
          groupName: this.groupMetadataDetail.groupName ,  
          isActive: this.groupMetadataDetail.isActive
        });
    }
    addGroupDbMetadata(params:any){
      this.loaderService.setLoading(true);
      this.service.postCreateGroupDbMetadata(params)
      .pipe(
        finalize(() => {
          this.loaderService.setLoading(false);
        })
      )
      .subscribe({
        next: (response:any) => {
          if (response.status) {
            this.formSettingData=response.result;
            this.createForm(this.formSettingData);
            for (const mp of  this.formSettingData?.metadataDbMappingList) { 
              if( mp?.metadataDetail.name==="id"){
                if(mp.metadataDetail.value==="")
                  mp.metadataDetail.value=0;
                  this.groupForm.patchValue( {id:mp.metadataDetail.value});//update new id in UI form
              }
            }
            if(this.formEventPropagationAfterSave){
              this.sendFormDataAfterSave( this.groupForm.value);
              }
            this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });
          }else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
          }
        },
        error: (err: HttpErrorResponse) =>  {
          console.log(err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Occurred.' });
        }
      });
    }
    updateGroupDbMetadata(params:any){
      this.loaderService.setLoading(true);
      this.service.putUpdateGroupDbMetadata(params)
      .pipe(
        finalize(() => {
          this.loaderService.setLoading(false);
        })
      )
      .subscribe({
        next: (response:any) => {
          if (response.status) {  
            this.sendFormData( this.groupForm.value);
            if(this.formEventPropagationAfterSave){
              this.sendFormDataAfterSave( this.groupForm.value);
              }
            this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });
        }
        else 
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
         
        },
        error: (err: HttpErrorResponse) =>  {
          console.log(err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error Occurred.' });
        }
      });
     }
     getGroupMetadataDynamicFormDetails(  ){ 
      this.loaderService.setLoading(true);
      //this.service.getGroupMetadataDetail(this.selectedGroupId) 
      this.service.getGroupDbMetadataDetailByGcPidId(this.metadataGroupCode,this.parentRecordId,this.recordId)
      .pipe(
        finalize(() => {
          this.loaderService.setLoading(false);
        })
      )
      .subscribe({
        next: (response:any) => {
          if (response.status) {
            //write code to show preview 
            this.groupMetadataDetail=response.result;
             this.formSetData ={};
            if(this.groupMetadataDetail?.modelData!=undefined) {
              if(this.groupMetadataDetail?.modelData?.length===0){ 
                this.formSetData ={};
              } else{  
                if(this.allowMultipleChildRecord){
                  if(this.recordId==0){
                    this.formSetData ={};
                  }else{
                   
                  this.formSetData =this.groupMetadataDetail?.modelData[0];  
                  }
                }
              } 
            } 
             
            this.formSettingData=Object.assign({...this.groupMetadataDetail}); //this.service.getFormSettingDatafromGroupMetadata(this.groupMetadataDetail);
            //this.messageService.add({ severity: 'success', summary: 'Success', detail: response.message });
            
          } else {
            this.groupMetadataDetail =  null; 
            this.messageService.add({ severity: 'warn', summary: 'Waring', detail: response.message });
            
          }
          this.createForm(this.formSettingData);
          if(this.isFormGenerated)
            this.groupForm.patchValue( this.formSetData );
        },
        error: (err: HttpErrorResponse) => console.log(err?.message)
      });
    
    }
    getJSONFromString(str:string):any{
      if(str==undefined)
        return null;
      else
        return JSON.parse(str);
    }
}
