<p-toast></p-toast>
<div class="container-fluid"> 
         <form [formGroup]="groupForm" class="form-inline" (ngSubmit)="onSubmit()">
            <!--div class="row"-->
            <ng-container [ngSwitch]="mp.metadataDetail.type" *ngFor="let mp of groupMetadataDetail?.metadataDbMappingList"> 
                <div class="row {{mp?.groupMetadataDbMappingDetail?.isHide?'d-none':''}}" >
                    <ng-container [ngSwitch]="mp.metadataDetail.type">
                        <ng-container *ngSwitchCase="'Range'"> 
                            <div class="col-lg-6 col-md-6 mb-2 ">
                                <label [for]="mp.metadataDetail.name" *ngIf="mp.metadataDetail.label !== ''" class="ms-2 fw-bold" >{{ mp.metadataDetail.label }} [<i class="{{mp.metadataDetail.options.icon}}"  ></i>]<span class="text-danger" *ngIf="mp.metadataDetail.validators.required">*</span></label>
                                
                                <div class="form-group">  
                                    <input class="form-control mb-2 mr-sm-2" 
                                    type="number"
                                    [min]="mp.metadataDetail.options.min"
                                    [max]="mp.metadataDetail.options.max"
                                    [step]="mp.metadataDetail.options.step" 
                                    [formControlName]="mp.metadataDetail.name"
                                    [value]="mp.metadataDetail.value==''?0: mp.metadataDetail.value" />
                                     
                                </div> 
                            </div>    
                        </ng-container>
                        <ng-container *ngSwitchCase="'Editor'" >
                            <div class="col-lg-12 col-md-12 mb-2 ">
                            <label [for]="mp.metadataDetail.name" *ngIf="mp.metadataDetail.label !== ''" class="ms-2  fw-bold" >{{ mp.metadataDetail.label }} <span class="text-danger" *ngIf="mp.metadataDetail.validators.required">*</span></label>
                                 <div class="form-group">  
                                    <angular-editor [id]="mp.metadataDetail.id" [formControlName]="mp.metadataDetail.name" [config]="editorConfig" ngDefaultControl  placeholder="Enter text here..." ></angular-editor>
                                 </div>   
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Checkbox'" >
                            <div class="form-check">
                            <label [for]="mp.metadataDetail.name" *ngIf="mp.metadataDetail.label !== ''" class="form-check-label  fw-bold" >{{ mp.metadataDetail.label }} <span class="text-danger" *ngIf="mp.metadataDetail.validators.required">*</span></label>
                                 <input type="checkbox" class="form-check-input mb-2 mr-sm-2"
                                    *ngIf="mp.metadataDetail.type === 'Checkbox'"
                                    [formControlName]="mp.metadataDetail.name"
                                    [checked]="mp.metadataDetail.value"  />     
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault >
                            <div class="col-lg-6 col-md-6 mb-2 ">
                                <label *ngIf="mp.metadataDetail.label !== '' && mp.metadataDetail.type !== 'Hidden'"  class="ms-2  fw-bold" >{{ mp.metadataDetail.label }} <span class="text-danger" *ngIf="mp.metadataDetail.validators.required">*</span></label>
                                <input class="form-control mb-2 mr-sm-2"
                                    *ngIf="
                                    [
                                        'Text',
                                        'Password',
                                        'Email',
                                        'Number',
                                        'Search',
                                        'Tel',
                                        'Url'
                                    ].includes(mp.metadataDetail.type)
                                    "
                                    [type]="mp.metadataDetail.type"
                                    [formControlName]="mp.metadataDetail.name"
                                    [value]="mp.metadataDetail.value" />
                                    <textarea class="form-control mb-2 mr-sm-2"
                                    *ngIf="mp.metadataDetail.type === 'Textarea'"
                                    [formControlName]="mp.metadataDetail.name"
                                    [value]="mp.metadataDetail.value"
                                    ></textarea>
                                   

                                    <select class="form-select" aria-label="Default types"
                                    *ngIf="mp.metadataDetail.type === 'Dropdown'"
                                    [formControlName]="mp.metadataDetail.name"   (change)="onSelectType($event)" >
                                        <option *ngFor="let dtOption of getJSONFromString(mp.groupMetadataDbMappingDetail.sourceData)" [ngValue]="dtOption">
                                    {{dtOption}}</option>
                                    </select>
                                    <input type="hidden" class="form-control mb-2 mr-sm-2"
                                    *ngIf="mp.metadataDetail.type === 'Hidden'"
                                    [formControlName]="mp.metadataDetail.name"
                                    [value]="mp.metadataDetail.value"  /> 
                            </div>
                        </ng-container>  
                    </ng-container>     
                </div>
            </ng-container> 
            <!--/div-->
            <div class="row">
                <div class="modal-footer justify-content-right">
                    <button type="submit" class="btn btn-primary btn-sm" *ngIf="formSettingData!=null">Update</button>
                    <button type="button" class="btn btn-warning btn-sm ms-2" *ngIf="formSettingData!=null" (click)="onResetClick()">Reset</button>
                
                    <button type="submit" class="btn btn-danger btn-sm ms-2" *ngIf="backButton==true" (click)="onGoBackClick()">Back</button>
                </div>
            </div> 
        </form>
</div>