import { Component,OnInit } from '@angular/core';
import { debounceTime, fromEvent, map, of, merge, tap, Observable } from 'rxjs';
import { BaseService } from './core/services/base.service';
import { EventService } from './event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'VAULT-(TMS - Macmillan Learning)';

  constructor(public baseService: BaseService,private service: EventService) {
    //this.loadAppSettings();
  }
    
  loadAppSettings() {
    this.baseService.getAppSettings()
    .subscribe( {next: (response) => {
      this.baseService.appSettings = response;
      localStorage.setItem("tms.config", JSON.stringify(response));
      this.baseService.baseUrl = response.baseUrl;
    },
    error:(error) => {
      console.log("error :" + error);
    }});
  }
  inactiveTime: number = 300000; //5 min set, if user is inactive  then we log in console

  ngOnInit() {
    merge(fromEvent(window, 'api-call-triggered'), of('load'))
      .pipe(
        tap((event: any) => {
        }),
        debounceTime(this.inactiveTime),
        tap((event) => {
          console.log(
            `You have been inactive for ${
              this.inactiveTime
            } ms. Last event triggered: ${event.type || event}`
          );
        })
      )
      .subscribe();
  }

  fetchT() {
    this.service.fetchT().subscribe();
  }
}
